import { useState, useRef, useEffect } from 'react';
import arrowIcon from '../../assets/dashboard/arrow-small.svg';
import useClickOutside from '../../hooks/useClickOutside';
import { t } from '../../i18n/i18n';
import Checkbox from '../Checkbox/Checkbox';
import './MultipleSelect.scss';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

const MultipleSelect = ({
  options,
  onSelect,
  title,
  selectedList,
  classNames,
  showSelectedAmount,
  firstSelected = false,
  isCreateTestGroup = false,
  CustomArrow,
  customArrowClassName,
  emptyOptionsText,
  buttonClassNames = '',
  disabled,
  openDrowdown = false,
}) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef();

  useClickOutside(selectRef, () => setOpen(false));
  useEffect(() => {
    setOpen(false);
  }, [openDrowdown]);
  return (
    <div ref={selectRef} className={`d-flex flex-column position-relative ${classNames}`}>
      <div
        tabIndex={0}
        data-testid="multiple-select-button"
        role="button"
        onKeyPress={() => {}}
        onClick={() => setOpen(!open)}
        className={`multiple-select-container px-2 py-1 non-selectable ${
          !isCreateTestGroup ? (open ? 'border-open' : 'border-closed') : ''
        } ${isCreateTestGroup ? 'border rounded' : 'select-border'}
        ${buttonClassNames}`}
      >
        <span
          className={`font-montserrat mr-2 ${isCreateTestGroup ? 'text-sm' : 'text-xs'} ${
            firstSelected ? 'font-weight-500 text-muted' : 'font-weight-bold'
          }`}
          data-testid="multiple-select-title-span"
        >
          {selectedList && selectedList?.length && firstSelected
            ? options.find((option) => option.id === selectedList[0])?.displayName
            : title}
          <span className="ml-1 font-montserrat font-weight-bold text-light-dark">
            {showSelectedAmount &&
              (selectedList?.length || 0) > 1 &&
              `(+${(selectedList?.length || 0) - 1})`}
          </span>
        </span>
        {CustomArrow ? (
          <CustomArrow
            classNames={`dropdown-select-arrow ${
              open && 'dropdown-select-arrow-open'
            } ${customArrowClassName}`}
          />
        ) : (
          <img
            src={arrowIcon}
            alt={t('ARROW_ALT')}
            className={`dropdown-select-arrow ${open && 'dropdown-select-arrow-open'}`}
            data-testid="multiple-select-arrow-img"
          />
        )}
      </div>
      {open && (
        <ul
          className="multiple-select-options-container select-border list-unstyled position-absolute bg-white border-1 border-open py-3 px-3 rounded"
          data-testid="multiple-select-list-ul"
        >
          {options?.length ? (
            options?.map((item, index) => (
              <li
                key={`MultipleSelectLi-${index}`}
                className="py-1 px-2 w-100 d-flex align-items-center text-nowrap"
                data-testid={`multiple-select-option-${index}-li`}
                style={{ pointerEvents: disabled ? 'none' : 'auto' }}
              >
                <Checkbox
                  testid={`multiple-select-checkbox-${item.id}`}
                  checked={selectedList?.includes(item.id) ?? false}
                  onClick={() => onSelect(item)}
                />
                <span
                  className="ml-2 font-montserrat text-xs font-weight-bold"
                  data-testid="multiple-select-title-li-span"
                >
                  {item.displayName}
                </span>
              </li>
            ))
          ) : (
            <EmptyComponent className="my-3 align-items-center py-3" title={emptyOptionsText} />
          )}
        </ul>
      )}
    </div>
  );
};

export default MultipleSelect;
