import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './cardScreening.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { CheckCircleOutlined, FieldTimeOutlined, LockOutlined } from '@ant-design/icons';
import { CiViewTable } from 'react-icons/ci';
import ShareIcon from '../../assets/screeningSearchs/ShareIcon';
import ExamIcon from '../../assets/screeningSearchs/ExamIcon';
import Progress from '../Progress/Progress';
import { t } from '../../i18n/i18n';
import CopyCheck from '../../assets/search/Check';
import AddInvitations from '../../assets/search/AddInvitations';
import CopyLink from '../../assets/search/Link';
import {
  setEmailsData,
  setNewSearchData,
  setSearchWasAlreadyCreated,
  setSelectedSearch,
  setSendEmailFrom,
  setSubjectEmail,
} from '../../redux/actions/screeningActions';
import DeleteForever from '../../assets/screening/DeleteForever';
import DuplicateEvaluation from '../DuplicateEvaluation/DuplicateEvaluation';
import useModalDetails from '../../hooks/useModalDetails';
import Modal from '../Modal/Modal';
import ArrowInvite from '../../assets/screeningSearchs/ArrowInvite';
import ModalDeleteDraft from './ModalDeleteDraft';
import VerticalDotsDropdown from '../VerticalDotsDropdown/VerticalDotsDropdown';
import DeleteIcon from '../../assets/profile/Delete';
import EditPencilIcon from '../../assets/common/EditPencilIcon';
import People from '../../assets/screeningSearchs/People';
import StatsIcon from '../../assets/screeningSearchs/StatsIcon';
import useSearchDashboard from '../../hooks/useSearchDashboard';
import CalendarClose from '../../assets/screeningSearchs/CalendarClose';
import useClickOutside from '../../hooks/useClickOutside';
import ArchiveBoxIcon from '../../assets/screeningSearchs/ArchiveBoxIcon';
import {
  getCustomSearch,
  getStageScreening,
  updateProctoringEnabledRecruitSessionStage,
} from '../../API/services/screeningService';
import { disableForWorkspaceRole } from '../../utils/workspaceRoleValidation';
import { reOpenEvaluation } from '../../API/services/companyService';
import {
  determineDisabledFeature,
  determineDisabledMinimumTier,
} from '../../utils/determineDisabled';
import { PartnerWorkspaceRole, PlanFeaturesIds } from '../../constants/enums';
import CameraProctoringEnabled from '../../assets/screeningSearchs/CameraProctoringEnabled';
import CameraProctoringDisabled from '../../assets/screeningSearchs/CameraProctoringDisabled';
import { ModalProctoringEnabled } from './ModalProctoringEnabled';
import InfoIcon from '../../assets/search/InfoIcon';
import MoveScreeningIcon from '../../assets/screeningSearchs/MoveScreeningIcon';
import ModalMoveScreening from './ModalMoveScreening';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';
import PencilDraft from '../../assets/screening/PencilDraft';
import Reopen from '../../assets/screening/Reopen';

const CardScreeningSearch = ({ data, fetchDraft, reloadSearches, setSearchesQuantity }) => {
  const { user, currentRole } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const isCardScreeningSearch = true;
  const { handleScreeningModalData, screeningModalContent, closeModal, loadingModal, showModal } =
    useModalDetails();
  const { showConfirmationModal, setShowConfirmationModal, confirmCloseSearchModalContent } =
    useSearchDashboard(data.recruitSessionId, isCardScreeningSearch, reloadSearches);
  const cardRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showProctoringEnabledModal, setShowProctoringEnabledModal] = useState(false);
  const [proctoringDataModal, setProctoringDataModal] = useState({
    proctoringEnabled: false,
    recruitSessionStageId: '',
  });
  const [showMoveScreeningModal, setShowMoveScreeningModal] = useState(false);
  const [moveScreeningDataModal, setMoveScreeningDataModal] = useState({
    partnerId: '',
    recruitSessionStageId: '',
  });

  const [copySuccess, setCopySuccess] = useState(false);
  const [publicIdCopySuccess, setPublicIdCopySuccess] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [disabledWorkspaceFunctions, setDisabledWorkspaceFunctions] = useState(true);

  useEffect(() => {
    setDisabledWorkspaceFunctions(
      disableForWorkspaceRole(currentRole, [
        PartnerWorkspaceRole.OWNER,
        PartnerWorkspaceRole.ADMINISTRATOR,
      ])
    );
  }, []);

  useClickOutside(cardRef, () => setOpenDropdown(false));

  const today = new Date();
  const searchIsFinish = new Date(data.recruitSessionStageEndDate) < today;
  moment.locale('es');

  const progressBars = [
    {
      percent: ((data.createdAccounts * 100) / data.invitationsSent).toFixed(0),
      value: `${data.createdAccounts}/${data.invitationsSent}`,
      translate: t('CREATED_ACCOUNTS'),
      visible: true,
    },
    {
      percent: ((data.totalPrefiltered * 100) / data.invitationsSent).toFixed(0),
      value: `${data.totalPrefiltered}/${data.invitationsSent}`,
      translate: t('SCREENINGS_PREFILTERED'),
      visible: data?.prefilterId ?? false,
    },
    {
      percent: ((data.evaluationsStarted * 100) / data.invitationsSent).toFixed(0),
      value: `${data.evaluationsStarted}/${data.invitationsSent}`,
      translate: t('SCREENINGS_STARTED'),
      visible: true,
    },
    {
      percent: ((data.evaluationsFinished * 100) / data.invitationsSent).toFixed(0),
      value: `${data.evaluationsFinished}/${data.invitationsSent}`,
      translate: t('SCREENINGS_FINISHED'),
      visible: true,
    },
  ];
  const newSearch = {
    name: data?.recruitSessionStageName,
    stages: [
      {
        id: data?.recruitSessionStageId,
      },
    ],
  };

  const selectedSearch = {
    stages: [
      {
        id: data?.recruitSessionStageId,
      },
    ],
  };

  const addInvitations = () => {
    dispatch(setSendEmailFrom('edit'));
    dispatch(
      setNewSearchData({
        ...newSearch,
        ...selectedSearch,
        step: 4,
      })
    );
    getCustomSearch(data.recruitSessionId).then((res) => {
      dispatch(setSelectedSearch(res));
    });
    dispatch(setSubjectEmail({ subjectEmail: '' }));
    dispatch(setSearchWasAlreadyCreated(true));
    dispatch(setEmailsData({}));
    history.push('/createsearch/send');
  };

  const copyLink = () => {
    navigator.clipboard.writeText(data.invitationLink);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const goToSearch = () => {
    history.push(
      data.isDraft ? `/createevaluation?draftId=${data.id}` : `/search/${data.recruitSessionId}`
    );
  };

  const editStage = () => {
    history.push(`/search/${data.recruitSessionId}?editDates=1?editName=1`);
  };

  const goToSearchStats = () => {
    history.push(`/search/${data.recruitSessionId}?stats=${data.recruitSessionStageId}`);
  };

  const openModalForShowTests = async () => {
    const stage = await getStageScreening({
      companyId: getLocalStorageWorkspace()?.companyId,
      levelId: data.screeningId,
    });
    handleScreeningModalData(stage.id);
  };

  const closeEvaluation = () => {
    setOpenDropdown(false);
    setShowConfirmationModal(true);
  };

  const setProctoringShowModal = ({ proctoringEnabled, recruitSessionStageId = '' }) => {
    setOpenDropdown(false);
    setShowProctoringEnabledModal(true);
    setProctoringDataModal({
      proctoringEnabled,
      recruitSessionStageId,
    });
  };

  const setMoveScreeningModal = (pId, data) => {
    setOpenDropdown(false);
    setShowMoveScreeningModal(true);
    setMoveScreeningDataModal({
      partnerId: pId,
      id: data.isDraft ? data.id : data.recruitSessionStageId,
      isDraft: data.isDraft,
    });
  };

  const onClickProctoringToggle = () => {
    updateProctoringEnabledRecruitSessionStage(proctoringDataModal.recruitSessionStageId, {
      proctoringEnabled: !proctoringDataModal.proctoringEnabled,
    })
      .then(() => {
        setShowProctoringEnabledModal(false);
        reloadSearches();
      })
      .catch(() => {
        setShowProctoringEnabledModal(false);
      });
  };

  const deleteDraft = () => {
    setOpenDropdown(false);
    setShowDeleteModal(true);
  };

  const handleReOpenEvaluation = async (recruitSessionId) => {
    await reOpenEvaluation(recruitSessionId);
    reloadSearches();
  };

  const copyStagePublicId = async () => {
    await navigator.clipboard.writeText(data.recruitSessionStagePublicId);
    setPublicIdCopySuccess(true);
    setTimeout(() => {
      setPublicIdCopySuccess(false);
    }, 1000);
  };

  const dropdownOptions = [
    {
      icon: <EditPencilIcon className="icon" fill="#757575" width="14" height="14" />,
      text: t('EDIT'),
      disabled: false,
      action: () => (data.isDraft ? goToSearch() : editStage()),
      itemClass: `dropdown-option ${!data.isActive && !data.isDraft && 'd-none'}`,
      testid: 'edit-button',
    },
    {
      customContent: (
        <DuplicateEvaluation
          screeningId={data.screeningId || data.id}
          name={data.isDraft ? data.name : data.recruitSessionStageName}
          isDraft={data.isDraft}
          disabled={disabledWorkspaceFunctions}
          isDropdown
        />
      ),
      itemClass: 'dropdown-option',
      testid: 'duplicate-button',
    },
    {
      icon: <EditPencilIcon className="icon" fill="#757575" width="14" height="14" />,
      text: t('RE_OPEN'),
      disabled: false,
      action: () => handleReOpenEvaluation(data.recruitSessionId),
      itemClass: `dropdown-option ${
        data.isActive || new Date() > new Date(data.recruitSessionStageEndDate) || data?.isDraft
          ? 'd-none'
          : ''
      }`,
      testid: 'edit-pencil-button',
    },
    {
      icon: <DeleteIcon className="icon" />,
      text: t('DELETE_TOOLTIP'),
      disabled: false,
      action: () => deleteDraft(),
      itemClass: `dropdown-delete-option ${!data.isDraft && 'd-none'}`,
      testid: 'delete-button',
    },
    {
      icon: <People className="icon" />,
      text: t('VIEW_CANDIDATES'),
      disabled: false,
      action: () => goToSearch(),
      itemClass: `dropdown-option ${
        (data.isDraft ||
          Number(data.invitationsSent) === 0 ||
          (Number(data.createdAccounts) === 0 && !data.isActive)) &&
        'd-none'
      }`,
      testid: 'view-candidates-button',
    },
    {
      icon: <StatsIcon className="icon" width="14" height="16" />,
      text: t('VIEW_STATS'),
      disabled: false,
      action: () => goToSearchStats(),
      itemClass: `dropdown-option ${
        (data.isDraft || Number(data.invitationsSent) === 0) && 'd-none'
      }`,
      testid: 'view-stats-button',
    },
    {
      icon: data.proctoringEnabled ? (
        <CameraProctoringDisabled className="icon" />
      ) : (
        <CameraProctoringEnabled className="icon" />
      ),
      text: data.proctoringEnabled
        ? t('DISABLED_PROCTORING_DROPDOWN')
        : t('ENABLED_PROCTORING_DROPDOWN'),
      disabled: false,
      action: () =>
        setProctoringShowModal({
          proctoringEnabled: data.proctoringEnabled,
          recruitSessionStageId: data.recruitSessionStageId,
        }),
      itemClass: `dropdown-option `,
      testid: 'proctoring-enabled-button',
    },
    {
      icon: searchIsFinish ? (
        <ArchiveBoxIcon className="icon" />
      ) : (
        <CalendarClose className="icon" />
      ),
      text: searchIsFinish ? t('SCREENING_DASHBOARD_FINISH_SEARCH') : t('FINISH_EVALUATION'),
      disabled: false,
      action: () => closeEvaluation(),
      itemClass: `${searchIsFinish ? 'dropdown-option' : 'dropdown-delete-option'} ${
        (data.isDraft || !data.isActive) && 'd-none'
      }`,
      testid: 'close-search-button',
    },
    {
      icon: <MoveScreeningIcon />,
      text: t('MOVE_WORKSPACE_MENU'),
      disabled: false,
      action: () => setMoveScreeningModal(user.id, data),
      itemClass: `${disabledWorkspaceFunctions ? 'd-none' : 'dropdown-option'}`,
      testId: 'move-search-icon',
    },
    {
      icon: publicIdCopySuccess ? <CopyCheck /> : <CopyLink height="16" />,
      text: publicIdCopySuccess ? t('COPIED_INVITATION_LINK') : t('COPY_PUBLIC_ID_LABEL'),
      disabled: determineDisabledMinimumTier(2) || !data.recruitSessionStagePublicId,
      action: () => copyStagePublicId(),
      itemClass: 'dropdown-option',
      testId: 'public-id-copy-icon',
    },
  ];

  const selectDraftText = (isSubtitle) => {
    if (!data.testGroupList?.lengt && !data?.customTestGroup)
      return t(isSubtitle ? 'ADD_TESTS_SUBTITLE' : 'ADD_TESTS');
    if (new Date(data.startDate) < new Date() || new Date(data.endDate) > new Date())
      return t(isSubtitle ? 'REVIEW_DATES_SUBTITLE' : 'REVIEW_DATES');
    if (!data.testGroupList.every((tg) => tg.weight))
      return t(isSubtitle ? 'ADD_WEIGHT_TESTS_SUBTITLE' : 'ADD_WEIGHT_TESTS');
    return t('OPEN_EVALUATION');
  };

  const selectSearchText = (isSubtitle) => {
    if (data?.isActive) {
      if (Number(data.invitationsSent) === 0 && new Date(data.recruitSessionStageEndDate) > today)
        return t(isSubtitle ? 'FOUND_YOUR_TALENT_SUBTITLE' : 'FOUND_YOUR_TALENT');
      if (Number(data.invitationsSent) > 0 && new Date(data.recruitSessionStageEndDate) > today) {
        return t(isSubtitle ? 'ENCOURAGE_PARTICIPATION_SUBTITLE' : 'ENCOURAGE_PARTICIPATION');
      }
      if (Number(data.invitationsSent) === 0 && searchIsFinish) {
        return t(isSubtitle ? 'FOUND_TALENT_FINISH_SUBTITLE' : 'FOUND_TALENT_FINISH');
      }
      return t(
        isSubtitle
          ? 'FOUND_TALENT_ENCOURAGE_PARTICIPATION_SUBTITLE'
          : 'FOUND_TALENT_ENCOURAGE_PARTICIPATION'
      );
    }
    return t(isSubtitle ? 'DONT_BE_DISCOURAGED_SUBTITLE' : 'DONT_BE_DISCOURAGED');
  };

  const searchStatus = () => {
    if (data.isDraft) return { label: t('SCREENING_SEARCH_DRAFT'), icon: <PencilDraft /> };
    if (!data.isActive) return { label: t('SEARCH_INACTIVE'), icon: <LockOutlined /> };
    if (searchIsFinish)
      return {
        label: t('SEARCH_EXPIRED'),
        description: t('SEARCH_FINISH_DESCRIPTION'),
        icon: (
          <FieldTimeOutlined style={{ color: '#686868', strokeWidth: 10 }} className="icon-check" />
        ),
      };
    if (new Date(data.recruitSessionStageStartDate) > today) return t('SEARCH_SHEDULED');
    return {
      label: t('SEARCH_ACTIVE'),
      icon: (
        <CheckCircleOutlined style={{ color: '#51C0F7', strokeWidth: 10 }} className="icon-check" />
      ),
    };
  };

  const setBgColor = () => {
    if (data.isDraft) return 'draft-bg';
    if (!data.isActive) return 'closed-bg';
    if (searchIsFinish) return 'closed-bg';
    return 'open-bg';
  };

  const setTextColor = () => {
    if (data.isDraft) return '#686868';
    if (!data.isActive) return '#272727';
    if (searchIsFinish) return '#272727';
    return '#51C0F7';
  };

  const [flipped, setFlipped] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => setFlipped(true), 1000);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setFlipped(false);
  };
  return (
    <div
      ref={cardRef}
      data-testid="card-screening-search"
      className="col col-12 col-md-6 col-xl-4 p-0 card-height p-2"
    >
      <Modal
        show={showModal}
        relativeCloseButton
        className="rounded px-4 py-2 justify-content-center w-80vw"
        showCloseButton={!loadingModal}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={(e) => closeModal(e)}
      >
        {screeningModalContent()}
      </Modal>
      <ModalDeleteDraft
        showDeleteModal={showDeleteModal}
        user={user}
        partnerId={data?.partner?.id}
        setShowDeleteModal={setShowDeleteModal}
        draftId={data.id}
        fetchDrafts={fetchDraft}
        setSearchesQuantity={setSearchesQuantity}
      />
      <Modal
        show={showConfirmationModal}
        className="close-search-modal-container"
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={setShowConfirmationModal}
        showCloseButton={false}
      >
        {confirmCloseSearchModalContent()}
      </Modal>
      <ModalProctoringEnabled
        setShowModal={setShowProctoringEnabledModal}
        showModal={showProctoringEnabledModal}
        data={proctoringDataModal}
        submitAction={onClickProctoringToggle}
        disabledButtonProps
      />
      <ModalMoveScreening
        setShowModal={setShowMoveScreeningModal}
        showModal={showMoveScreeningModal}
        data={moveScreeningDataModal}
        reloadSearches={reloadSearches}
      />
      <div className="h-100 m-0  d-flex flex-column screening-search-container bg-white">
        <div className="b-radius-8 d-flex h-100 flex-column justify-content-center">
          <div
            className={`px-3 screening-title-container d-flex justify-content-between align-items-center ${setBgColor()}`}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="" placement="top">
                  {data?.isDraft ? data.name : data.recruitSessionStageName}
                </Tooltip>
              }
            >
              <Link
                data-testid="name-button"
                rel="noreferrer noopener"
                to={
                  data.isDraft
                    ? `/createevaluation?draftId=${data.id}`
                    : `/search/${data.recruitSessionId}`
                }
                className="pt-2 text-decoration-none text-left border-0 bg-transparent overflow-hidden text-truncate font-weight-600 text-md title-screening description-card-search"
                style={{ whiteSpace: 'nowrap' }}
              >
                {data.isDraft ? data?.name : data?.recruitSessionStageName}
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                searchStatus().description ? (
                  <Tooltip className="" placement="top">
                    {searchStatus().description}
                  </Tooltip>
                ) : (
                  <div />
                )
              }
            >
              <div className="d-flex px-2 align-items-center gap-1 text-xs font-weight-600">
                <span style={{ color: setTextColor() }}>{searchStatus().label?.toUpperCase()}</span>
                <span className="d-flex align-items-center mr-2">{searchStatus().icon}</span>
              </div>
            </OverlayTrigger>
            <span className="position-dots">
              <VerticalDotsDropdown
                options={dropdownOptions}
                className="mr-2 width-selectable"
                setOpenDropdown={setOpenDropdown}
                openDropdown={openDropdown}
                disabled={disabledWorkspaceFunctions}
              />
            </span>
          </div>
          <div className="d-flex mb-3 icons-position align-items-center" />
          <div
            className={`card-screenings ${flipped && 'flipped'}`}
            onClick={() => !data.isDraft && setFlipped(!flipped)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className="card-front-screenings"
              onMouseEnter={() => !data.isDraft && handleMouseEnter}
            >
              <div className="d-flex align-items-center gap-2">
                {!data.isDraft && (
                  <div className="align-items-center justify-content-center">
                    {data?.evaluationsPendingCredits > 0 && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip className="">{t('EVALUATIONS_PENDING_CREDITS')}</Tooltip>}
                      >
                        <div>
                          <a
                            className="text-sm diamond-background d-flex align-items-center justify-content-center rounded-circle"
                            href={process.env.RAZZLE_RUNTIME_SALES_CALENDAR_URL}
                          >
                            <InfoIcon color="#ff7676" />
                          </a>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                )}
                <div className="d-flex flex-column w-100">
                  {!data.isDraft ? (
                    progressBars.map((pb) => (
                      <div key={pb.translate} className={`mb-2 text-xs ${!pb.visible && 'd-none'}`}>
                        <span className=" font-weight-400 mb-2 color-grey-1">
                          <span className="mr-2 font-weight-600">{pb.translate}</span>
                          {pb.value}
                        </span>
                        <Progress
                          percent={pb.percent}
                          barColor="linear-gradient(90deg, #59C2F2 0%, #9AF45C 100%)"
                        />
                      </div>
                    ))
                  ) : (
                    <div className="d-flex flex-column">
                      <div className="d-flex text-xs text-weight-600 justify-content-between border-bottom-dotted">
                        <span>{t('CREATE_SCREENING_SELECTED_TESTS')}</span>
                        <span>{`${data?.testGroupList?.length} ${t(
                          'CREATE_EVALUATION_TESTS_TITLE'
                        )}`}</span>
                      </div>
                      <div className="d-flex text-xs text-weight-600 justify-content-between border-bottom-dotted">
                        <span>{t('CREATE_SCREENING_SELECTED_TESTS')}</span>
                        <span>{`${data?.customTestGroup?.tests?.length ?? 0} ${t(
                          'QUESTIONS'
                        )?.toLowerCase()}`}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card-back-screenings text-xs font-weight-600 d-flex flex-column gap-1 pt-0">
              <div className="d-flex justify-content-between border-bottom-dotted">
                <span>{t('SCREENING_INVITATIONS_SENT')}</span>
                <span>{`${data.invitationsSent} ${t('SCREENING_GUESTS')}`}</span>
              </div>
              <div className="d-flex justify-content-between border-bottom-dotted">
                <span>{t('SCREENING_COMPLETED_LABEL')}</span>
                <span>{`${data.evaluationsFinished} ${t('SCREENING_COMPLETED_TIMES_LABEL')}`}</span>
              </div>
              <div className="d-flex justify-content-between border-bottom-dotted">
                <span>{t('DASHBOARD_SEARCH_HEADERS_LIMIT_DATE')}</span>
                <span>
                  {data.recruitSessionStageEmptyDates
                    ? t('SCREENING_NO_LIMIT_DATE')
                    : moment(data.recruitSessionStageEndDate).format('D MMM YYYY')}
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex position-relative w-100 justify-content-between pb-1 px-2 mt-3">
            {(data?.partner?.firstName || data?.partnerFirstName) && (
              <span
                data-testid="span-created-by"
                className="mt-auto  text-muted text-xs font-weight-400 overflow-hidden text-truncate italic px-2"
              >
                {`${t('RECRUIT_SESSION_DRAFT_CREATED_BY')} ${
                  data.isDraft
                    ? ` ${data?.partner?.firstName} ${data?.partner?.lastName}`
                    : ` ${data?.partnerFirstName} ${data?.partnerLastName}`
                }`}
              </span>
            )}
            {Boolean(data?.isActive) &&
              Boolean(Number(data.invitationsSent) === 0) &&
              new Date(data.recruitSessionStageEndDate) > today && (
                <span
                  data-testid="arrow-invite-span"
                  className="mr-2 d-flex align-items-center h-100"
                >
                  <ArrowInvite />
                </span>
              )}
            <span className="font-weight-400 italic text-xs text-muted px-2 text-truncate">
              {moment(data.recruitSessionStageStartDate).format('D MMM YYYY')}
            </span>
          </div>
        </div>
        <div
          data-testid="footer-card"
          className="mt-auto footer-card d-flex justify-content-between"
        >
          <div className="d-flex justify-content-center w-100 gap-1">
            {data.isDraft || new Date(data.recruitSessionStageEndDate) < today || !data.isActive ? (
              <>
                {data.isDraft ? (
                  <div className="d-flex gap-2">
                    <button
                      onClick={() => {
                        goToSearch();
                      }}
                      className="text-xs font-weight-600 bg-transparent d-flex align-items-center border-0 bg-white text-underline gap-1"
                      type="button"
                    >
                      <span className="">{t('EDIT')}</span>
                      <EditPencilIcon fill="#367B98" width={15} height={16} />
                    </button>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip className="">{t('DELETE_TOOLTIP')}</Tooltip>}
                    >
                      <button
                        disabled={disabledWorkspaceFunctions}
                        className="border-0 outline-none bg-transparent ml-2 d-flex align-items-center gap-1"
                        type="button"
                        data-testid="delete-draft-button"
                        onClick={() => {
                          deleteDraft();
                        }}
                      >
                        <span className="text-xs font-weight-600 bg-transparent d-flex align-items-center border-0 bg-white text-underline">
                          {t('DELETE_TOOLTIP')}
                        </span>
                        <span className="d-flex align-items-center">
                          <DeleteForever fill="#367B98" width={15} height={16} />
                        </span>
                      </button>
                    </OverlayTrigger>
                  </div>
                ) : (
                  <div className="d-flex gap-2">
                    <div className="d-flex align-items-center">
                      <button
                        onClick={() => {
                          handleReOpenEvaluation(data.recruitSessionId);
                        }}
                        className="text-xs font-weight-600 bg-transparent d-flex align-items-center border-0 bg-white text-underline gap-1"
                        type="button"
                      >
                        <span className="">{t('RE_OPEN')}</span>
                        <span>
                          <Reopen />
                        </span>
                      </button>
                    </div>
                    <DuplicateEvaluation
                      screeningId={data.screeningId || data.id}
                      name={data.isDraft ? data.name : data.recruitSessionStageName}
                      isDraft={data.isDraft}
                      disabled={disabledWorkspaceFunctions}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    openModalForShowTests();
                  }}
                  className="text-xs font-weight-600 bg-transparent d-flex align-items-center border-0 bg-white text-underline gap-1"
                  type="button"
                >
                  {t('VIEW_SCREENING_TEST')}
                  <span>
                    <CiViewTable />
                  </span>
                </button>
                <button
                  disabled={disabledWorkspaceFunctions}
                  data-testid="copy-link-button"
                  type="button"
                  className={` text-xs font-weight-600 d-flex align-items-center gap-1 border-0 bg-transparent text-underline ${
                    determineDisabledFeature({ featureId: PlanFeaturesIds.SEARCH_LINKS })
                      ? 'displayNone'
                      : ''
                  }`}
                  onClick={copyLink}
                >
                  {copySuccess ? (
                    <span className="d-flex gap-1">
                      {t('COPIED_INVITATION_LINK')}
                      <CopyCheck />
                    </span>
                  ) : (
                    <span className="d-flex gap-1">
                      {t('COPY_INVITATION_LINK')}
                      <CopyLink width="12" height="12" />
                    </span>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardScreeningSearch;
