import React from 'react';
import 'react-quill/dist/quill.snow.css';

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const SafeContainer = ({
  content,
  theme = 'bubble',
  style,
  modulesAllow,
  handleChange,
  readOnly = false,
  placeholder,
  title,
}) =>
  typeof window !== 'undefined' &&
  ReactQuill && (
    <ReactQuill
      theme={theme}
      value={content}
      className={style}
      modules={modulesAllow}
      onChange={handleChange}
      readOnly={readOnly}
      placeholder={placeholder}
      title={title}
    />
  );

export default SafeContainer;
