import React from 'react';
import { t } from '../../i18n/i18n';
import { getTextColor } from '../../utils/colors/determineColor';
import { getLocalStorageWorkspace } from '../../utils/workspaceUtils';
import { defaultColorCompany } from '../../constants/colors';

const EvaluationPreview = ({ evaluationData, children, className }) => {
  const workspaceInfo = getLocalStorageWorkspace();
  const colorSet = evaluationData?.color1
    ? evaluationData?.color1
    : workspaceInfo?.companyPrimaryColor
    ? workspaceInfo?.companyPrimaryColor
    : defaultColorCompany;
  return (
    <div className={`d-flex flex-column w-80 ${className ?? 'mt-3'}`}>
      <div
        style={{
          border: `1px solid ${colorSet || '#ccc'}`,
          minHeight: '-webkit-fill-available',
        }}
        className="mt-2"
      >
        <div
          className="py-3 px-4"
          data-testid="evaluation-preview"
          style={{
            backgroundColor: colorSet || '#fff',
            color: getTextColor(colorSet || '#fff'),
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            {!workspaceInfo?.companyLogo ? (
              <span className="font-montserrat font-weight-600 text-base ml-3">
                {workspaceInfo?.companyName}
              </span>
            ) : (
              <img
                className="rounded-circle profile-personalization-img"
                src={workspaceInfo?.companyLogo || ''}
                alt="Profile Logo"
              />
            )}
            <span className="font-montserrat font-weight-600 text-base ml-3">
              {t('PERSONALIZATION_TITLE_OF_EXAMPLE')}
            </span>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default EvaluationPreview;
