import { SET_USER_SUMMARY, GET_USER_SUMMARY, CLEAN_USER_SUMMARY } from '../actionTypes';

export const getUserSummary = () => ({
  type: GET_USER_SUMMARY,
});

export const setUserSummary = (payload) => ({
  type: SET_USER_SUMMARY,
  payload,
});

export const cleanFiltersUserSummary = () => ({
  type: CLEAN_USER_SUMMARY,
});
