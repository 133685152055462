import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NewStyleInput from '../../../components/NewStyleInput/NewStyleInput';
import buttonEditImage from '../../../assets/profile/button-edit-image.svg';
import profileImageNull from '../../../assets/profile/profile.png';
import { t } from '../../../i18n/i18n';
import { getTextColor } from '../../../utils/colors/determineColor';
import { uploadImagePartner } from '../../../API/services/alkymersService';
import { setCurrentRole, setCurrentWorkspace, setUser } from '../../../redux/actions/authActions';
import { saveCompany } from '../../../API/services/companyService';
import { getMe } from '../../../API/services/authService';
import {
  getLocalStorageWorkspace,
  hasCompanyRootAccess,
  setLocalStorageWorkspace,
} from '../../../utils/workspaceUtils';
import { defaultColorCompany } from '../../../constants/colors';
import ModalCustom from '../../../components/ModalCustom/ModalCustom';
import EvaluationPreview from '../../../components/EvaluationPreview/EvaluationPreview';

const Evaluations = () => {
  const { user, currentWorkspace, currentRole } = useSelector((state) => state?.auth);
  const history = useHistory();
  const [showModalCompanyPicture, setShowModalCompanyPicture] = useState(false);
  const [showModalCompanyColor, setShowModalCompanyColor] = useState(false);
  const [evaluationData, setEvaluationData] = useState({
    color1: getLocalStorageWorkspace()?.companyPrimaryColor || defaultColorCompany,
  });
  const dispatch = useDispatch();
  const setData = (e) => {
    setEvaluationData({ ...evaluationData, [e.target.name]: e.target.value });
  };
  const [allowCompanyAccess, setAllowCompanyAccess] = useState(
    hasCompanyRootAccess(getLocalStorageWorkspace()?.companyId, user?.workspaces)
  );
  const uploadImage = async (e) => {
    const fmData = new FormData();
    fmData.append('file', e.target.files[0]);
    if (['image/png', 'image/jpeg', 'image/jpg'].includes(e.target.files[0]?.type)) {
      await uploadImagePartner(fmData).then((res) => {
        dispatch(
          setUser({
            ...user,
            workspaces: user?.workspaces?.map((workspace) =>
              workspace.workspaceId === currentWorkspace
                ? {
                    ...workspace,
                    companyLogo: res?.company?.picture,
                  }
                : workspace
            ),
          })
        );
        setLocalStorageWorkspace({
          ...getLocalStorageWorkspace(),
          companyLogo: res?.company?.picture,
        });
      });
    } else {
      notification.open({
        message: t('IMAGE_FORMAT_INVALID'),
        type: 'error',
      });
    }
  };

  const handleSave = () => {
    if (
      (getLocalStorageWorkspace()?.companyPrimaryColor || defaultColorCompany) !==
      evaluationData?.color1
    ) {
      setShowModalCompanyColor(true);
    } else {
      handleSaveColor();
    }
  };

  const handleSaveColor = () => {
    setShowModalCompanyColor(false);
    saveCompany({
      ...(evaluationData?.color1 ? { primaryColor: evaluationData?.color1 } : {}),
    }).then(() => {
      notification.open({
        message: t('PROFILE_UPDATED_SUCCESSFULLY'),
        icon: <i className="fas fa-check" style={{ color: '#58DDA6' }} />,
      });
      dispatch(
        setUser({
          ...user,
          company: {
            primaryColor: evaluationData?.color1,
          },
        })
      );
      getMe()
        .then((data) => {
          dispatch(setUser(data));
          dispatch(setCurrentWorkspace(currentWorkspace));
          setLocalStorageWorkspace({
            ...getLocalStorageWorkspace(),
            companyPrimaryColor: evaluationData?.color1,
          });
          dispatch(setCurrentRole(currentRole));
        })
        .catch((error) => {
          TrackEvent('partner-get-me-error', { error });
          history.push('/error');
        });
    });
  };

  const handleCancel = () => {
    setEvaluationData({
      ...evaluationData,
      color1: getLocalStorageWorkspace()?.companyPrimaryColor || defaultColorCompany,
    });
  };

  return (
    <div data-testid="evaluations-tab" className="d-flex flex-column">
      <ModalCustom
        show={showModalCompanyPicture}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={setShowModalCompanyPicture}
        showCross={false}
        onOk={() => {
          document.getElementById('file-input-company-input').click();
          setShowModalCompanyPicture(false);
        }}
        onOkText={t('UNDERSTOOD')}
        onCancel={() => setShowModalCompanyPicture(false)}
        onCancelText={t('CANCEL')}
        datatestidbuttonok="button-understood"
        title={t('TITLE_MODAL_CHANGE_COMPANY_PICTURE')}
        subtitle={t('SUBTITLE_MODAL_CHANGE_COMPANY_PICTURE')}
      />
      <div className="d-flex flex-row">
        <div className="d-flex flex-column w-50">
          <span className="font-montserrat font-weight-600 text-base mt-2">
            {t('PERSONALIZED_EXPERIENCE_COMPANY')}
          </span>
          <span className="font-montserrat mt-2">{t('PERSONALIZED_EXPERIENCE_COMPANY_SUB')}</span>
          <div className="d-flex flex-row mt-3">
            <NewStyleInput
              className="mt-3 mr-2"
              value={getLocalStorageWorkspace()?.companyName}
              onChange={setData}
              disabled
              title="Nombre de la empresa"
              name="companyName"
              classNameInput="w-350"
              titleBold
            />
            <div className="w-100 mt-2">
              <span className="font-montserrat text-sm mt-3 font-weight-600">
                {t('PROFILE_MAIN_COLOR')}
              </span>
              <div className="d-flex align-items-center mt-2 px-2 py-1 input-type-color-profile">
                <ModalCustom
                  show={showModalCompanyColor}
                  closeBtnClassname="text-maya-blue font-weight-bold"
                  setShow={setShowModalCompanyColor}
                  showCross={false}
                  onOk={handleSaveColor}
                  onOkText={t('UNDERSTOOD')}
                  onCancel={() => setShowModalCompanyColor(false)}
                  onCancelText={t('CANCEL')}
                  datatestidbuttonok="button-understood"
                  title={t('TITLE_MODAL_CHANGE_COMPANY_COLOR')}
                  subtitle={t('SUBTITLE_MODAL_CHANGE_COMPANY_COLOR')}
                />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="" placement="top">
                      {!allowCompanyAccess
                        ? t('NOT_ACCESS_WHITELABEL_EDIT')
                        : t('ACCESS_WHITELABEL_EDIT')}
                    </Tooltip>
                  }
                >
                  <input
                    type="color"
                    onChange={setData}
                    className={`input-picker-color-custom ${
                      !allowCompanyAccess ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    style={{ width: '450px' }}
                    name="color1"
                    value={evaluationData.color1}
                    disabled={!allowCompanyAccess}
                  />
                </OverlayTrigger>
                <span className="font-montserrat font-weight-600 text-base ml-2 text-uppercase">
                  {evaluationData.color1}
                </span>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column position-relative mt-4"
            style={{ width: 'fit-content' }}
          >
            <span className="font-montserrat text-base font-weight-600">Logo</span>
            <img
              className="user-heading-img rounded-circle p-1"
              src={getLocalStorageWorkspace()?.companyLogo || profileImageNull}
              alt={t('PROFILE_MENU_LOGO_USER')}
            />
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="" placement="top">
                  {!allowCompanyAccess
                    ? t('NOT_ACCESS_WHITELABEL_EDIT')
                    : t('ACCESS_WHITELABEL_EDIT')}
                </Tooltip>
              }
            >
              <div className="image-upload position-absolute bottom-0 right-0">
                <button
                  type="button"
                  onClick={() => setShowModalCompanyPicture(true)}
                  disabled={!allowCompanyAccess}
                  className={`button-image ${
                    !allowCompanyAccess ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                >
                  <label htmlFor="file-input-company" className="m-0 p-0">
                    <img
                      src={buttonEditImage}
                      className={`button-edit-image-profile ${
                        !allowCompanyAccess ? 'cursor-not-allowed' : 'cursor-pointer'
                      }`}
                      alt={t('EDIT_ALT')}
                    />
                  </label>
                </button>
                <input
                  id="file-input-company-input"
                  type="file"
                  accept="image/x-png,image/jpeg"
                  style={{ display: 'none' }}
                  disabled={!allowCompanyAccess}
                  onChange={uploadImage}
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div className="d-flex flex-column w-50 mt-3">
          <span className="font-montserrat font-weight-600 text-base text-black">
            {t('PERSONALIZATION_PREVIEW')}
          </span>
          <EvaluationPreview evaluationData={evaluationData}>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center p-4 py-5 mt-2">
              <button
                type="button"
                data-testid="personalization-button"
                className="px-4 py-2 font-montserrat border-0 font-weight-700 text-sm profile-personalization-button"
                style={{
                  backgroundColor: evaluationData?.color1,
                  color: getTextColor(evaluationData?.color1 || ''),
                }}
              >
                {t('PERSONALIZATION_BUTTON_OF_EXAMPLE')}
              </button>
              <span className="font-montserrat font-weight-600 text-sm text-black text-decoration-none mt-2 mt-lg-0 ml-0 ml-md-5 cursor-pointer">
                {t('PERSONALIZATION_LINK_OF_EXAMPLE')}
              </span>
            </div>
          </EvaluationPreview>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between mt-5 mb-3">
        <button
          onClick={handleCancel}
          type="submit"
          data-testid="workspace-personalize-cancel-button"
          className="float-right mt-3 border-0 px-4 py-1 bg-transparent"
          style={{ borderRadius: '50px' }}
        >
          <span className="font-montserrat text-xs font-weight-700 text-tab-selected">
            {t('CANCEL')}
          </span>
        </button>
        <button
          onClick={handleSave}
          type="submit"
          data-testid="workspace-personalize-save-button"
          className="float-right mt-3 button-save-profile border-0 px-4 py-1"
        >
          <span className="font-montserrat text-xs font-weight-700 text-white">{t('SAVE')}</span>
        </button>
      </div>
    </div>
  );
};

export default Evaluations;
