import { useHistory } from 'react-router-dom';
import EvaluationIcon from '../../assets/cardWorkspace/EvaluationIcon';
import MembersIcon from '../../assets/cardWorkspace/MembersIcon';
import StarComplete from '../../assets/tests/StarComplete';
import StarEmpty from '../../assets/tests/StarEmpty';
import { PartnerWorkspaceRole } from '../../constants/enums';
import { t } from '../../i18n/i18n';
import { determineIconType } from '../../utils/determineIconWorkspace/determineIconWorkspace';
import './CardWorkspace.scss';
import { CONFIGURATION, CONFIGURATION_MEMBERS, SCREENING_SEARCHES } from '../../constants/routes';
import { addTruncate } from '../../utils/addTruncate';
import { Button, Dropdown } from 'antd';
import { FaArrowRight, FaArrowRightFromBracket } from 'react-icons/fa6';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { TbTransfer } from 'react-icons/tb';

const CardWorkspace = ({
  workspace,
  addFavorite,
  removeFavorite,
  setActiveWorkspace,
  handleLeaveWorkspace,
}) => {
  const isCard = true;
  const history = useHistory();
  const dataWorkspace = [
    {
      id: '1',
      icon: <EvaluationIcon />,
      name: t('EVALUATION'),
      quantity: workspace.evaluations,
      action: () => setWorkspaceAndGoToRoute(SCREENING_SEARCHES),
    },
    {
      id: '2',
      icon: <MembersIcon />,
      name: t('MEMBERS'),
      quantity: workspace.members,
      action: () => setWorkspaceAndGoToRoute(CONFIGURATION_MEMBERS),
    },
  ];
  const menuItems = [
    {
      id: '1',
      label: (
        <span onClick={() => setWorkspaceAndGoToRoute(CONFIGURATION_MEMBERS)}>
          {t('WORKSPACE_ADD_MEMBER')}
        </span>
      ),
    },
    workspace.workspaceRole === PartnerWorkspaceRole.OWNER && {
      id: '2',
      label: (
        <span
          onClick={() => {
            setWorkspaceAndGoToRoute(CONFIGURATION);
          }}
        >
          {t('CONFIGURATE_WORKSPACE')}
        </span>
      ),
    },
    {
      id: '3',
      label: (
        <span onClick={() => setWorkspaceAndGoToRoute(SCREENING_SEARCHES)}>
          {t('WORKSPACE_SEE_SCREENINGS')}
        </span>
      ),
    },
    workspace.workspaceRole === PartnerWorkspaceRole.OWNER && {
      id: '4',
      label: (
        <span
          onClick={() => {
            setWorkspaceAndGoToRoute(CONFIGURATION_MEMBERS);
          }}
        >
          {t('TRANSFER_OWNERSHIP')}
        </span>
      ),
    },
  ];

  const setWorkspaceAndGoToRoute = (route) => {
    setActiveWorkspace(workspace.id);
    history.push(route);
  };

  return (
    <div data-testid="card-workspace" key={workspace.id} className="b-radius-8 bg-white size-card">
      <div
        style={{
          backgroundColor: workspace.color || '#0E9CE2',
        }}
        className="header-color d-flex justify-content-between align-items-center px-3 py-2"
      >
        {determineIconType(workspace, isCard)}
        <div className="d-flex gap-1">
          <button
            data-testid={`button-star-${workspace.id}`}
            type="button"
            className="bg-transparent border-0 ml-auto"
            onClick={() =>
              !workspace.favorite ? addFavorite(workspace?.id) : removeFavorite(workspace?.id)
            }
          >
            {workspace.favorite ? (
              <StarComplete testid={`star-complete-${workspace.id}`} />
            ) : (
              <StarEmpty />
            )}
          </button>
          <Dropdown menu={{ items: menuItems }} className="cursor-pointer">
            <BsThreeDotsVertical color="white " className="align-self-center" />
          </Dropdown>
        </div>
      </div>
      <div className="overflow-hidden px-4 pt-3">
        <p className=" font-montserrat font-weight-600 text-md">
          {addTruncate(workspace.name, 25)}
        </p>
        <p className="font-weight-600 text-xs  workspace-role-text">{t(workspace.workspaceRole)}</p>
      </div>
      <div className="px-4 pt-3">
        {dataWorkspace.map((option) => (
          <Button
            key={option.id}
            className="px-3 d-flex mt-2 align-items-center justify-content-between w-100 button-option-workspace"
            onClick={option?.action}
          >
            <div className="d-flex gap-1 align-items-center">
              {option.icon}
              <div className="d-flex gap-1">
                <span className="font-weight-500 font-montserrat text-sm">{option.name}</span>
                <span className="font-weight-600 font-montserrat text-sm">{option.quantity}</span>
              </div>
            </div>
            <FaArrowRight />
          </Button>
        ))}
      </div>
      <div className={`d-flex align-items-center px-4 my-4 w-100`}>
        {workspace.workspaceRole === PartnerWorkspaceRole.OWNER ? (
          <button
            data-testid="config-workspace-button"
            type="button"
            className="border-0 bg-transparent d-flex gap-1 align-items-center"
            onClick={() => setWorkspaceAndGoToRoute(CONFIGURATION_MEMBERS)}
          >
            <TbTransfer color={'#41479B'} />
            <span className="workspace-button-text font-weight-700 text-sm">
              {t('TRANSFER_OWNERSHIP')}
            </span>
          </button>
        ) : (
          <button
            data-testid="leave-workspace-button"
            type="button"
            className="border-0 bg-transparent d-flex gap-1 align-items-center"
            onClick={() => handleLeaveWorkspace(workspace)}
          >
            <FaArrowRightFromBracket color={'#41479B'} />
            <span className="workspace-button-text font-weight-700 text-sm">
              {t('LEAVE_WORKSPACE')}
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default CardWorkspace;
