import './loading_recommendations.scss';
const LoadingRecommendations = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="300"
    height="300"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        className="interface"
        d="M2215 4989 c-298 -11 -717 -48 -797 -70 -20 -5 -46 -21 -57 -36 -21
-25 -21 -36 -21 -540 l0 -515 -22 5 c-13 3 -66 13 -118 22 -52 9 -111 19 -130
23 l-35 8 -5 432 -5 432 -27 27 c-27 27 -28 27 -89 14 -88 -18 -300 -83 -394
-120 -182 -73 -327 -177 -370 -266 -19 -39 -20 -67 -20 -1015 l0 -975 31 -55
c42 -74 124 -139 259 -206 152 -74 432 -165 469 -151 9 4 16 18 16 31 0 22 -7
27 -57 41 -150 42 -205 60 -298 97 -158 63 -283 144 -324 212 -21 33 -21 41
-21 892 0 472 0 863 0 868 0 4 17 -6 37 -24 217 -191 834 -356 1533 -411 80
-6 170 -14 200 -17 l55 -7 3 -701 c2 -753 1 -735 52 -719 20 6 20 12 20 399
l0 393 92 7 c118 8 618 8 736 0 l92 -7 0 -579 0 -579 24 -24 c13 -13 37 -27
52 -31 16 -4 116 0 224 10 738 65 1291 205 1535 389 62 46 88 76 121 137 18
34 19 70 19 1015 0 937 -1 982 -19 1029 -58 150 -295 282 -691 386 -124 32
-141 32 -171 -1 -18 -20 -19 -48 -24 -472 l-5 -451 -35 -8 c-19 -4 -78 -14
-130 -23 -52 -9 -105 -19 -117 -22 l-23 -5 0 508 c0 487 -1 510 -20 541 -24
40 -52 48 -240 68 -239 24 -382 34 -650 45 -280 11 -359 11 -655 -1z m1035
-88 c237 -22 416 -43 438 -52 l22 -9 0 -509 0 -509 -37 -6 c-87 -14 -298 -36
-436 -46 -82 -5 -157 -15 -167 -20 -48 -26 -50 -37 -50 -335 l0 -280 -460 0
-460 0 0 280 c0 298 -2 309 -50 335 -10 5 -85 15 -167 20 -138 10 -349 32
-435 46 l-38 6 0 509 0 509 23 9 c27 12 261 38 457 51 80 6 165 12 190 14 95
8 1061 -3 1170 -13z m1107 -182 c131 -38 306 -108 391 -158 67 -39 140 -106
161 -148 16 -31 14 -129 -3 -161 -39 -74 -163 -158 -331 -225 -95 -38 -310
-103 -376 -113 l-39 -7 0 425 c0 234 2 427 5 430 7 7 54 -4 192 -43z m-3397
-399 c0 -366 -2 -410 -16 -410 -28 0 -209 49 -310 84 -131 46 -182 68 -264
116 -204 119 -227 253 -62 374 99 72 323 165 536 221 50 13 97 24 104 25 9 0
12 -88 12 -410z m3939 -1936 c-93 -152 -470 -296 -1016 -389 -269 -45 -742
-95 -777 -82 -15 6 -16 87 -14 889 l3 883 55 6 c30 4 118 12 195 19 417 34
860 115 1150 211 122 40 320 139 375 187 l45 40 3 -865 c2 -862 2 -866 -19
-899z"
      />
      <path
        className="interface"
        d="M2586 4597 l-26 -26 0 -234 c0 -216 2 -237 19 -258 19 -24 21 -24
293 -30 150 -4 317 -10 371 -14 145 -12 137 -28 137 270 0 229 -1 243 -20 260
-26 24 -211 42 -516 51 l-232 7 -26 -26z"
      />
      <path
        className="interface"
        d="M1846 4524 c-88 -7 -165 -15 -173 -18 -15 -6 -18 -52 -3 -61 5 -3 99
1 207 10 109 8 220 16 247 18 52 2 74 21 63 51 -8 20 -104 20 -341 0z"
      />
      <path
        className="interface"
        d="M1846 4174 c-88 -7 -165 -15 -173 -18 -17 -7 -17 -55 0 -62 7 -2 95
2 196 11 100 8 209 15 241 15 68 0 97 18 80 51 -9 17 -21 19 -98 18 -48 -1
-159 -7 -246 -15z"
      />
      <path
        className="interface"
        d="M4546 3494 c-235 -93 -533 -158 -976 -214 -91 -11 -173 -23 -182 -26
-24 -7 -23 -51 1 -59 35 -11 443 43 676 90 265 54 594 159 618 197 15 23 1 48
-25 47 -13 0 -63 -16 -112 -35z"
      />
      <path
        className="interface"
        d="M4581 3034 c-190 -88 -627 -182 -1081 -235 -58 -6 -111 -16 -117 -22
-18 -14 -16 -46 3 -54 22 -8 290 22 502 58 282 47 556 118 717 185 80 34 86
38 83 63 -4 35 -38 37 -107 5z"
      />
      <path
        className="interface"
        d="M4610 2643 c-120 -54 -365 -123 -594 -168 -129 -26 -159 -44 -121
-75 20 -17 77 -10 272 35 172 40 337 90 451 137 67 28 73 33 70 57 -4 32 -28
36 -78 14z"
      />
      <path
        className="interface"
        d="M999 3485 c-37 -7 -92 -25 -123 -40 -298 -141 -420 -487 -274 -782
74 -152 284 -312 296 -226 2 17 -10 30 -56 62 -272 184 -311 552 -82 781 102
102 259 160 394 145 171 -19 304 -104 400 -257 38 -60 70 -76 83 -41 10 26
-46 121 -114 194 -131 139 -337 203 -524 164z"
      />
      <path
        className="interface"
        d="M1004 3209 c-169 -64 -249 -262 -171 -421 94 -192 348 -237 497 -88
161 161 103 430 -110 511 -64 24 -150 24 -216 -2z"
      />
      <path
        className="interface"
        d="M1625 2918 c-2 -7 -6 -38 -9 -68 -4 -30 -18 -89 -33 -132 -27 -80
-26 -108 5 -108 27 0 37 15 65 100 32 96 44 185 27 205 -15 18 -49 20 -55 3z"
      />
      <path
        className="interface"
        d="M1243 2536 c-177 -58 -284 -227 -252 -400 19 -109 63 -164 98 -123 8
10 5 28 -10 68 -46 118 -18 242 71 322 80 72 174 92 281 61 44 -13 66 -28 117
-80 48 -51 67 -64 82 -59 57 18 -26 132 -136 186 -76 38 -181 48 -251 25z"
      />
      <path
        className="hand"
        d="M1300 2323 c-37 -15 -72 -48 -91 -86 -39 -75 -29 -104 126 -372 76
-132 143 -247 148 -257 8 -13 3 -24 -23 -54 -18 -21 -44 -62 -58 -92 -21 -47
-24 -65 -20 -136 l4 -83 129 -224 c72 -123 144 -240 162 -260 42 -46 108 -81
214 -113 101 -31 157 -65 197 -121 16 -22 70 -113 121 -203 100 -175 117 -193
190 -194 45 0 19 -13 346 175 411 237 421 243 439 281 32 69 25 91 -93 270
-118 179 -146 238 -162 349 -22 150 -40 224 -72 292 -60 125 -367 649 -397
677 -70 65 -169 58 -237 -16 l-41 -46 -36 15 c-19 8 -49 15 -66 15 -45 0 -107
-35 -133 -75 -15 -23 -27 -33 -37 -29 -86 37 -144 29 -202 -27 -30 -29 -44
-36 -50 -27 -4 7 -42 73 -85 147 -93 160 -127 193 -205 197 -29 2 -60 1 -68
-3z m119 -90 c17 -16 112 -170 212 -343 99 -173 186 -318 192 -322 7 -5 19 -7
27 -5 33 5 20 49 -49 170 -83 144 -90 180 -42 228 32 32 69 37 111 16 15 -8
53 -61 100 -142 77 -133 101 -155 129 -121 11 13 3 33 -48 120 -72 124 -78
161 -32 207 37 37 88 39 125 7 14 -13 50 -66 79 -118 56 -97 80 -117 106 -86
10 12 5 30 -33 99 -25 46 -46 93 -46 105 0 37 22 71 55 87 82 38 93 26 305
-341 102 -175 195 -348 207 -384 12 -36 27 -108 33 -160 19 -152 51 -234 158
-399 133 -205 128 -195 117 -227 -8 -22 -72 -63 -354 -226 -224 -129 -354
-198 -372 -198 -34 0 -54 20 -97 98 l-32 60 237 139 c243 141 267 161 230 192
-12 9 -58 -13 -243 -120 -126 -72 -236 -135 -246 -140 -13 -7 -23 3 -54 58
-45 80 -103 146 -158 178 -23 12 -87 37 -143 55 -144 45 -157 59 -302 309 -67
115 -126 223 -132 241 -6 17 -9 60 -7 95 3 51 10 73 33 107 17 23 35 43 40 45
6 2 54 -72 107 -164 79 -138 100 -168 119 -168 56 0 48 17 -225 490 -161 278
-266 469 -266 485 2 85 98 129 159 73z"
      />
      <path
        className="line-4"
        d="M4489 1734 c-18 -22 -9 -33 110 -142 88 -81 104 -91 121 -82 11 6 20
19 20 29 0 21 -202 211 -224 211 -7 0 -19 -7 -27 -16z"
      />
      <path
        className="line-1"
        d="M566 1651 c-94 -6 -117 -19 -102 -56 6 -17 18 -17 154 -11 157 7 189
17 166 54 -13 19 -68 23 -218 13z"
      />
      <path
        className="line-3"
        d="M3742 1463 c-5 -10 -12 -56 -16 -103 -4 -47 -9 -108 -13 -136 -5 -47
-3 -53 17 -64 39 -21 49 3 62 151 6 75 9 143 5 152 -8 22 -44 22 -55 0z"
      />
      <path
        className="line-2"
        d="M1083 923 c-66 -85 -143 -202 -143 -217 0 -19 32 -37 48 -27 18 11
172 233 172 248 0 11 -29 33 -44 33 -2 0 -17 -17 -33 -37z"
      />
    </g>
  </svg>
);

export default LoadingRecommendations;
