import { CLEAN_USER_SUMMARY, SET_USER_SUMMARY } from '../actionTypes';

const initialState = {
  userSummaryPaginated: {
    list: [],
    filter: {},
    tabsFilter: [ { name: 'WITHOUT_ACCEPT', total: 5, }, { name: 'WITHOUT_START', total: 133, }, { name: 'PROCESSED', total: 470, }, ],
    pagination: { limit: 10, offset: 0 },
    isLoading: false,
    reload: false,
  },
};

function userSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_SUMMARY: {
      return {
        ...state,
        userSummaryPaginated: { ...state.userSummaryPaginated, ...action.payload },
      };
    }
    case CLEAN_USER_SUMMARY: {
      return {
        ...state,
        userSummaryPaginated: {
          ...state.userSummaryPaginated,
          filter: {
            limit: state.userSummaryPaginated?.filter?.limit,
            offset: state.userSummaryPaginated?.filter?.offset,
          },
        },
      };
    }
    default:
      return state;
  }
}

export default userSummaryReducer;
