import React, { useState } from 'react';
import { enGB, es, pt } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { t } from '../../i18n/i18n';
import { ContentToDisplayEnum, Orders } from '../../constants/enums';
import BackgroundTop from '../../components/BackgroundTop/BackgroundTop';
import '../ScreeningInvitationsList.scss';
import '../../components/CreateEvaluationSecondStep/CreateScreeningStep2.scss';
import './UsersSummary.scss';
import EditEmailTemplate from '../../components/EditEmailTemplate/EditEmailTemplate';
import { postSendEmailCustom } from '../../API/services/screeningService';

import { setAppliedFilters } from '../../redux/actions/profilesActions';
import useModalDetails from '../../hooks/useModalDetails';
import Modal from '../../components/Modal/Modal';
import { breadcrumbAlkymetricsDashboardCandidates } from '../../constants/breadcrumb';
import { useUserSummary } from '../../hooks/useUserSummary';
import { HeaderUsersSummary } from '../../components/UsersSummary/HeaderUsersSummary';
import { TableUsersSummary } from '../../components/UsersSummary/TableUsersSummary';

registerLocale('en', enGB);
registerLocale('pt', pt);
registerLocale('es', es);

const UsersSummary = () => {
  const userSummary = useSelector((state) => state.userSummary.userSummaryPaginated);
  const endPagination = useSelector(
    (state) => state.userSummary.userSummaryPaginated.pagination.endPagination
  );

  const { getUserSummaryForTable, sendMail, setSendMail } = useUserSummary();
  const { currentWorkspace } = useSelector((state) => state?.auth);

  const fetchDataTable = async (page = 0, filters = {}) => {
    if (
      (page > 0 && Object.keys(filters).length && endPagination) ||
      userSummary?.isLoading ||
      userSummary?.reload
    ) {
      return;
    }

    await getUserSummaryForTable({
      ...filters,
      workspaceIds: `${currentWorkspace}`,
      limit: 20,
      offset: page,
    });
  };

  const { screeningModalContent, closeModal, loadingModal, showModal } = useModalDetails();

  const [editMailVisible, setEditMailVisible] = useState(false);

  const { template, subjectEmail } = useSelector((state) => state.screenings);

  const { appliedFilters } = useSelector((state) => state.profiles);
  const dispatch = useDispatch();

  const handleGoBack = () => {
    setEditMailVisible(false);
    setSendMail('');
  };

  const handleSendEmail = async (error, setLoading, emailsToSend = []) => {
    const requestData = {
      emails: emailsToSend,
      emailType: 'CUSTOM_GENERIC',
      emailParams: {
        subject: subjectEmail.subjectEmail,
        html: template.template,
      },
    };
    const response = await postSendEmailCustom(requestData);
    if (response === '') {
      notification.open({
        message: t('Email enviado'),
        icon: <i className="fa fa-check" style={{ color: 'green' }} />,
      });
      setEditMailVisible(false);
      setSendMail('');
    } else {
      notification.open({
        message: t('No se pudo enviar el email'),
        icon: <i className="fa fa-check" style={{ color: 'red' }} />,
      });
    }
  };

  const addTabFilter = async (filterToApply) => {
    if (userSummary?.isLoading) {
      return;
    }

    await fetchDataTable(0, { customFilters: filterToApply }, true);
  };

  const optionHeaders = [
    {
      columnOrder: 1,
      key: 'candidate',
      name: t('CANDIDATE'),
      width: '250px',
      pinned: false,
      checked: true,
    },
    {
      columnOrder: 2,
      key: 'lastActivity',
      name: t('DASHBOARD_CANDIDATES_LASTACTIVITY'),
      width: '150px',
      pinned: false,
      selected: true,
      checked: true,
      order: Orders.DESC,
      className: 'text-center',
    },
    {
      columnOrder: 3,
      key: 'lastEvaluation',
      name: t('DASHBOARD_CANDIDATES_LASTEVALUATION'),
      width: '270px',
      pinned: false,
      checked: true,
    },
    {
      columnOrder: 4,
      key: 'lastMail',
      name: t('DASHBOARD_CANDIDATES_LAST_EMAIL'),
      width: '150px',
      pinned: false,
      checked: false,
    },
    {
      columnOrder: 5,
      key: 'avgScore',
      name: t('DASHBOARD_CANDIDATES_AVG_SCORE'),
      width: '150px',
      pinned: false,
      checked: true,
      order: Orders.ASC,
    },
    {
      columnOrder: 6,
      key: 'totalScreeningInstances',
      name: t('DASHBOARD_CANDIDATES_EVALUATION_DID'),
      width: '150px',
      pinned: false,
      checked: true,
      order: Orders.ASC,
    },
    {
      columnOrder: 7,
      key: 'contacted',
      name: t('DASHBOARD_CANDIDATES_CONTACTED_FROM'),
      width: '150px',
      pinned: false,
      checked: true,
      // order: Orders.ASC,
    },
    {
      columnOrder: 8,
      key: 'countryName',
      name: t('COUNTRY'),
      width: '150px',
      pinned: false,
      checked: true,
    },
    {
      columnOrder: 9,
      key: 'cityName',
      name: t('PROVINCE'),
      width: '150px',
      pinned: false,
      checked: true,
    },
    {
      columnOrder: 10,
      key: 'actions',
      name: t('ACTIONS'),
      width: '150px',
      pinned: false,
      checked: true,
    },
  ];
  const idCustomTable = 'searchTableCandidatesPersonalization';
  const savedPersonalization = JSON.parse(localStorage.getItem(idCustomTable));

  const [headers, setHeaders] = useState(
    savedPersonalization?.length
      ? optionHeaders?.map((item, index) => ({
          ...item,
          checked: savedPersonalization[index]?.checked,
          pinned: savedPersonalization[index]?.pinned,
          columnOrder: savedPersonalization[index]?.columnOrder,
        }))
      : optionHeaders
  );

  return (
    <>
      {(editMailVisible || sendMail) && (
        <EditEmailTemplate
          handleSendRequest={handleSendEmail}
          goBack={handleGoBack}
          viewInfo={false}
          emailDashboard
          emails={
            sendMail
              ? [sendMail]
              : userSummary?.list?.filter((item) => item?.checked)?.map((item) => item?.email)
          }
          setEditMailVisible={setEditMailVisible}
        />
      )}
      <Modal
        show={showModal}
        relativeCloseButton
        className="rounded px-4 py-2 justify-content-center w-80vw"
        showCloseButton={!loadingModal}
        closeBtnClassname="text-maya-blue font-weight-bold"
        setShow={(e) => closeModal(e)}
      >
        {screeningModalContent()}
      </Modal>

      <div className={`w-100 mx-auto h-100 ${editMailVisible || sendMail ? 'd-none' : ''}`}>
        <BackgroundTop
          title={t(`CANDIDATES`)}
          routes={breadcrumbAlkymetricsDashboardCandidates}
          className="position-relative"
          titleClassname="text-xl ml-5"
          key={t(`CANDIDATES`)}
        />
        <div className="mw-100">
          <div className="position-absolute" />
          <div>
            <div className="px-2">
              {/* <div ref={containerRef} className="w-100" /> */}
              <div className="position-relative d-flex flex-column">
                <HeaderUsersSummary
                  addTabFilter={addTabFilter}
                  handleSubmit={fetchDataTable}
                  headers={headers}
                  setHeaders={setHeaders}
                  idCustomTable={idCustomTable}
                />
                <TableUsersSummary
                  fetchDataTable={fetchDataTable}
                  onClickSendMail={() => setEditMailVisible(true)}
                  headers={headers}
                  setHeaders={setHeaders}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div ref={fetchNextUrlRef} /> */}
      </div>
    </>
  );
};

export default UsersSummary;
