import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getCandidatesGeneralDashboard } from '../API/services/screeningService';
import { setUserSummary } from '../redux/actions/userSummaryActions';
import { INVITED_PROFILES_DETAIL } from '../constants/routes';
import ExternalLink from '../components/ExternalLink/ExternalLink';
import LinkedinIcon from '../assets/dashboard/LinkedinIcon';
import VerticalDotsDropdown from '../components/VerticalDotsDropdown/VerticalDotsDropdown';
import { t } from '../i18n/i18n';
import { ReviewTypeEnum, TestGroupTypes } from '../constants/enums';
import useTranslations from './useTranslations';
import EditPencilIcon from '../assets/common/EditPencilIcon';
import { useState } from 'react';
import EnvelopeOutlinedIcon from '../assets/dashboard/EnvelopeOutlinedIcon';

const DotsDropdown = ({ options }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <VerticalDotsDropdown
      options={options}
      setOpenDropdown={setOpenDropdown}
      className="mr-2 width-selectable"
      openDropdown={openDropdown}
    />
  );
};
export const useUserSummary = () => {
  const dispatch = useDispatch();
  const { key } = useTranslations();
  const [sendMail, setSendMail] = useState('');

  moment.updateLocale(key);
  const userSummary = useSelector((state) => state.userSummary.userSummaryPaginated);
  const calculateTimeDifference = (date) => {
    const now = moment();
    const lastUpdated = moment(date);

    const daysDiff = now.diff(lastUpdated, 'days');
    const weeksDiff = now.diff(lastUpdated, 'weeks');
    const monthsDiff = now.diff(lastUpdated, 'months');
    const yearsDiff = now.diff(lastUpdated, 'years');

    if (now.isSame(lastUpdated, 'day')) {
      return t('TODAY');
    }
    if (now.subtract(1, 'day').isSame(lastUpdated, 'day')) {
      return t('YESTERDAY');
    }
    if (yearsDiff >= 1) {
      return ` ${yearsDiff} ${yearsDiff > 1 ? t('YEARS') : t('YEAR')}`;
    }
    if (monthsDiff >= 1) {
      return ` ${monthsDiff} ${monthsDiff > 1 ? t('MONTHS') : t('MONTH')}`;
    }
    if (weeksDiff >= 1) {
      return ` ${weeksDiff} ${weeksDiff > 1 ? t('WEEKS') : t('WEEK')}`;
    }
    if (daysDiff >= 1) {
      return ` ${daysDiff} ${daysDiff > 1 ? t('DAYS') : t('DAY')}`;
    }
    return t('TODAY');
  };

  const getTypeEvaluation = (screeningInstance) => {
    if (!screeningInstance?.score || screeningInstance?.score === 0) {
      const totalTestGroups = screeningInstance?.testGroups?.filter(
        (item) => item?.type === TestGroupTypes.QUALITATIVE
      );
      if (totalTestGroups?.length === screeningInstance?.testGroups?.length) {
        return TestGroupTypes.QUALITATIVE;
      }
    }
    if (screeningInstance?.screening?.reviewType === ReviewTypeEnum.PSYCHOTECHNICS) {
      return ReviewTypeEnum.PSYCHOTECHNICS;
    }
    return TestGroupTypes.QUANTITATIVE;
  };

  const generateSubInformation = (date, key1, key2, highlight = false) => {
    const isTodayOrYesterday = [t('YESTERDAY'), t('TODAY')].includes(date);
    const baseText = isTodayOrYesterday
      ? t(key1)?.replace('{{value}}', date)
      : t(key2)?.split('{{value}}');

    if (!highlight || !Array.isArray(baseText)) return baseText;

    return (
      <>
        {baseText[0]}
        <span className="text-danger text-bold">{date}</span>
        {baseText[1]}
      </>
    );
  };

  const informationEvaluation = (item) => {
    const { lastAssessmentInvitation, lastComunication, dataAvg, lastScreeningInstance } = item;
    const type = getTypeEvaluation(lastScreeningInstance);
    let subInformation = <></>;

    if (
      lastAssessmentInvitation?.prefilterInstanceId &&
      lastAssessmentInvitation?.prefilterScore < 1
    ) {
      const date = calculateTimeDifference(new Date(lastAssessmentInvitation?.createdAt));
      subInformation = (
        <span>
          {generateSubInformation(date, 'INVITED_FROM_VALUE_2', 'INVITED_FROM_VALUE')} |{' '}
          {t('PREFILTER_NOT_APPROVED')}
        </span>
      );
    } else if (!lastAssessmentInvitation?.acceptedAt) {
      const date = calculateTimeDifference(new Date(lastAssessmentInvitation?.createdAt));
      subInformation = (
        <span>{generateSubInformation(date, 'INVITED_FROM_VALUE_2', 'INVITED_FROM_VALUE')}</span>
      );
    } else if (lastAssessmentInvitation?.acceptedAt && !lastAssessmentInvitation?.startDate) {
      const date = calculateTimeDifference(new Date(lastAssessmentInvitation?.createdAt));
      subInformation = (
        <span>
          {generateSubInformation(date, 'ACCEPTED_FROM_VALUE_2', 'ACCEPTED_FROM_VALUE', true)}
        </span>
      );
    } else if (!lastAssessmentInvitation?.endDate) {
      const date = moment(lastAssessmentInvitation?.startDate).format('DD-MMM-YYYY');
      subInformation = <span>{t('CANDIDATES_STARTED_FROM')?.replace('{{value}}', date)}</span>;
    } else {
      const date = moment(lastAssessmentInvitation?.endDate).format('DD-MMM-YYYY');
      const typeInfo = () => {
        if (type === TestGroupTypes.QUALITATIVE) return t(TestGroupTypes.QUALITATIVE);
        if (type === ReviewTypeEnum.PSYCHOTECHNICS) return t(ReviewTypeEnum.PSYCHOTECHNICS);
        return `${Number(lastAssessmentInvitation?.score)?.toFixed(0) || 0}%`;
      };
      subInformation = (
        <span>
          {t('CANDIDATES_ENDED_FROM')?.replace('{{value}}', date)} | {typeInfo()}
        </span>
      );
    }

    return (
      <div className="d-flex flex-column ">
        <div className="text-truncate ">
          <Link
            to={INVITED_PROFILES_DETAIL.replace(
              ':recruitSessionId',
              lastAssessmentInvitation?.recruitSessionStage?.recruitSessionId
            ).replace(':profileId', lastAssessmentInvitation?.profileId)}
          >
            <span className="font-weight-500 ">{lastAssessmentInvitation?.screeningName}</span>
          </Link>
        </div>
        {subInformation}
      </div>
    );
  };

  const mappingDataToRowTable = (data) =>
    data?.map((item, indexRow) => {
      const {
        lastAssessmentInvitation,
        lastComunication,
        dataAvg,
        lastScreeningInstance,
        firstAssessmentInvitation,
      } = item;

      const { profile } = lastAssessmentInvitation;
      return {
        ...item,
        assessmentInvitationStatus: lastAssessmentInvitation?.status,
        candidate: (
          <div className="d-flex flex-column">
            <span className="text-truncate text-black font-weight-500">{`${lastAssessmentInvitation?.firstName} ${lastAssessmentInvitation?.lastName}`}</span>
            <span className="text-truncate text-xs text-black font-weight-500">
              {lastAssessmentInvitation?.email}
            </span>
          </div>
        ),
        lastEvaluation: informationEvaluation(item),
        lastMail: lastAssessmentInvitation?.comunications?.length
          ? lastAssessmentInvitation?.comunications?.sort((a, b) => b?.createdAt - a?.createdAt)[0]
              ?.subjectEmail
          : '',
        avgScore: (
          <div className="position-relative" style={{ maxWidth: '50px' }}>
            <span
              className="font-weight-bold profiles-list-item-percent position-relative font-montserrat d-flex justify-content-center align-items-center"
              data-color="yellow"
            >
              <span
                className={`profiles-list-item-percent-subitem 
          ${
            !dataAvg?.avgScore
              ? 'no-score-bg'
              : dataAvg?.avgScore < 75
              ? `mix-yellow-${Math.floor(dataAvg?.avgScore)}`
              : `mix-green-${Math.floor(dataAvg?.avgScore) - 75}`
          }`}
              />
              {`${Number(dataAvg?.avgScore)?.toFixed(0) || 0}%`}
            </span>
          </div>
        ),

        totalScreeningInstances: `${Number(dataAvg?.totalScreeningInstances)?.toFixed(0) || 0}`,
        processStatus: lastAssessmentInvitation?.processStatus,
        lastActivity: moment(lastAssessmentInvitation?.updatedAt).format('DD-MMM-YYYY'),
        contacted: firstAssessmentInvitation?.createdAt ? (
          <span title={moment(firstAssessmentInvitation?.createdAt).format('DD-MMM-YYYY')}>
            {calculateTimeDifference(firstAssessmentInvitation?.createdAt)}
          </span>
        ) : (
          ''
        ),
        mailSent: lastAssessmentInvitation?.mailSent,
        totalEvents: lastAssessmentInvitation?.totalEvents,
        visible: lastAssessmentInvitation?.visible,
        pendingCredits: lastAssessmentInvitation?.pendingCredits,
        deletedByPartner: lastAssessmentInvitation?.deletedByPartner,
        countryName: profile?.address?.country?.displayName,
        cityName: profile?.address?.city?.displayName,
        email: lastAssessmentInvitation?.email,
        userId: profile?.id,
        firstName: lastAssessmentInvitation?.firstName,
        lastName: lastAssessmentInvitation?.lastName,
        actions: (
          <div
            className="d-flex py-3 flex-shrink-0 align-items-center  pl-2"
            style={{ width: '3.5rem' }}
          >
            <ExternalLink
              role="button"
              tabIndex={0}
              to={profile?.linkedinUrl}
              disabled={!profile?.linkedinUrl}
            >
              <LinkedinIcon
                className={`linkedin ${profile?.linkedinUrl ? 'withLink' : 'withoutLink'}`}
              />
            </ExternalLink>
            <DotsDropdown
              options={[
                {
                  icon: <EnvelopeOutlinedIcon className="icon" />,
                  text: t('SEARCH_TABLE_SEND_MAIL'),
                  action: () => {
                    setSendMail(lastAssessmentInvitation?.email);
                  },
                  itemClass: 'dropdown-option',
                  testid: 'button-send-email',
                },
              ]}
            />
          </div>
        ),
        score: lastAssessmentInvitation?.score || lastScreeningInstance?.score || 0,
        testgroupsResults: [],
      };
    });

  const getUserSummary = async (params) => {
    const { limit, offset, ...rest } = params;
    const response = await getCandidatesGeneralDashboard(params);
    const list = response?.data;
    const result = {
      list,
      filter: rest,
      pagination: { limit, offset },
    };
    dispatch(setUserSummary(result));
    return result;
  };
  const getUserSummaryForTable = async (filters) => {
    const { limit, offset, ...rest } = filters;

    dispatch(setUserSummary({ ...userSummary, isLoading: true, reload: offset === 0 }));

    try {
      if (offset > 0 && Object.keys(rest).length > 1 && userSummary?.pagination?.endPagination) {
        dispatch(setUserSummary({ ...userSummary, isLoading: false, reload: false }));
        return [];
      }
      const notIncludeProfileIds = userSummary?.list?.map(
        (item) => item?.lastAssessmentInvitation?.profileId
      );

      const responseData = await getCandidatesGeneralDashboard({
        ...filters,
        notIncludeProfileIds: offset ? notIncludeProfileIds?.join(',') : '',
      });

      const collection = responseData?.data?.collection;
      const pagination = responseData?.data?.pagination;
      const tabsFilter = responseData?.data?.extraData?.tabsFilter;

      const list = ['avgScore', 'totalScreeningInstances'].includes(filters?.sort)
        ? collection.sort((a, b) =>
            filters?.orderBy === 'DESC'
              ? b?.dataAvg[filters?.sort] - a?.dataAvg[filters?.sort]
              : a?.dataAvg[filters?.sort] - b?.dataAvg[filters?.sort]
          )
        : collection;

      const listWitoutRepeat = list;

      let total =
        !offset && !filters?.customFilters?.length
          ? pagination?.total
          : userSummary?.pagination?.total;

      let totalTabs = !offset
        ? filters?.customFilters?.length
          ? userSummary?.tabsFilter
          : tabsFilter
        : userSummary?.tabsFilter;

      if (['avgScore', 'totalScreeningInstances'].includes(filters?.sort)) {
        total = userSummary?.pagination?.total;
        totalTabs = userSummary?.tabsFilter;
      }

      const result = {
        list: offset
          ? [...userSummary?.list, ...mappingDataToRowTable(listWitoutRepeat)]
          : mappingDataToRowTable(listWitoutRepeat),
        filter: rest,
        pagination: {
          total,
          limit,
          offset,
          endPagination: !listWitoutRepeat?.length,
        },
        tabsFilter: totalTabs,
      };

      dispatch(setUserSummary({ ...result, isLoading: false, reload: false }));
      return result;
    } catch (error) {
      dispatch(setUserSummary({ isLoading: false, reload: false }));
    }
    return userSummary;
  };

  return { getUserSummary, getUserSummaryForTable, sendMail, setSendMail };
};
