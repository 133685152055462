import { useEffect, useRef } from 'react';

const InfiniteScroll = ({ onScrollEnd, isLoading, delay = 300 }) => {
  const timeoutRef = useRef(null);

  const handleScroll = () => {
    if (isLoading) return;

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const threshold = document.documentElement.scrollHeight - 10;

      if (scrollPosition >= threshold) {
        onScrollEnd();
      }
    }, delay);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onScrollEnd, isLoading]);

  return null;
};

export default InfiniteScroll;
