import { useEffect, useState } from 'react';
import './PersonalizeCanidatesTable.scss';
import { v4 } from 'uuid';
import ModalCustom from '../ModalCustom/ModalCustom';
import PersonalizeColumnItem from '../PersonalizeColumnItem/PersonalizeColumnItem';
import GearIcon from '../../assets/dashboard/GearIcon';
import { t } from '../../i18n/i18n';

const PersonalizeCanidatesTable = ({
  headers,
  onChangeHeaders,
  idCustomTable = 'searchTablePersonalization-',
}) => {
  const [showPersonalize, setShowPersonalize] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(v4());
  const [tablePersonalization, setTablePersonalization] = useState([]);
  const savedPersonalization = localStorage.getItem(idCustomTable);

  const saveNewPersonalization = (newPersonalization) => {
    localStorage.setItem(`${idCustomTable}`, JSON.stringify(newPersonalization));
  };

  const getAndSetSavedPersonalization = (defaultPersonalization) => {
    if (savedPersonalization) {
      const parsedPersonalization = JSON.parse(savedPersonalization);
      const personalization = defaultPersonalization.map((item) => {
        const itemFound = parsedPersonalization.find((element) => element.key === item.key);
        return {
          ...item,
          checked: itemFound?.checked ?? item.checked,
          pinned: itemFound?.pinned ?? item.pinned,
          columnOrder: itemFound?.columnOrder ?? item.columnOrder,
        };
      });
      setTablePersonalization(personalization);
    } else {
      setTablePersonalization(defaultPersonalization);
    }
  };

  const setDefaultConfig = () => {
    getAndSetSavedPersonalization(headers);
  };

  useEffect(() => {
    setDefaultConfig();
  }, [headers]);

  const handleColumnCheck = (key) => {
    const tempColumns = [...tablePersonalization];

    tempColumns.forEach((element, index) => {
      if (element.key === key) {
        tempColumns[index].checked = !tempColumns[index].checked;
        if (!element.checked) {
          tempColumns[index].pinned = false;
        }
      }
    });

    setTablePersonalization(tempColumns);
  };

  const handleOrder = (currentOrder, nextOrder) => {
    const tempColumns = [...tablePersonalization];
    const currentOrderItem = tempColumns?.find((item) => item.columnOrder === currentOrder);
    const nextOrderItem = tempColumns?.find((item) => item.columnOrder === nextOrder);
    const filteredData = tempColumns?.filter(
      (item) => item.key !== nextOrderItem.key && item.key !== currentOrderItem.key
    );
    currentOrderItem.columnOrder = nextOrder;
    nextOrderItem.columnOrder = currentOrder;
    setTablePersonalization([...filteredData, nextOrderItem, currentOrderItem]);
  };

  const handlePin = (key) => {
    const tempColumns = [...tablePersonalization];
    const itemToPin = tempColumns.findIndex((item) => item.key === key);
    tempColumns[itemToPin].pinned = !tempColumns[itemToPin].pinned;
    if (tempColumns[itemToPin].columnOrder > 2) {
      const firstItemIndex = tempColumns.findIndex((item) => item.columnOrder === 1);
      const secondItemIndex = tempColumns.findIndex((item) => item.columnOrder === 2);

      if (firstItemIndex >= 0) {
        if (!tempColumns[firstItemIndex].pinned) {
          tempColumns[firstItemIndex].columnOrder = tempColumns[itemToPin].columnOrder;
          tempColumns[itemToPin].columnOrder = 1;
        } else {
          if (secondItemIndex >= 0) {
            tempColumns[secondItemIndex].columnOrder = tempColumns[itemToPin].columnOrder;
          }
          tempColumns[itemToPin].columnOrder = 2;
        }
      } else {
        tempColumns[itemToPin].columnOrder = 1;
      }
    }

    setTablePersonalization(tempColumns);
  };

  const disableOrder = (direction, index) => {
    let isDisabled = false;

    if (direction === 'UP') {
      isDisabled = index === 0;
    }
    if (direction === 'DOWN') {
      const amountPinned = tablePersonalization.filter((item) => item.pinned).length;
      isDisabled =
        index === tablePersonalization.length - 1 ||
        (amountPinned > 0 && index === amountPinned - 1);
    }
    return isDisabled;
  };

  const applyEdition = () => {
    setShowPersonalize(false);
    saveNewPersonalization(tablePersonalization);
    onChangeHeaders(tablePersonalization);
  };

  const cancelEdition = () => {
    setShowPersonalize(false);
  };

  const openEdition = () => {
    setRerenderKey(v4());
    setDefaultConfig();
    setShowPersonalize(true);
  };

  const checkboxDisabled = (index) => {
    const amountChecked = tablePersonalization.filter((item) => item.checked);
    return amountChecked.length < 2 && tablePersonalization[index].checked;
  };

  return (
    <div>
      <button
        data-testid="personalize-search-table-button"
        className="personalize-button"
        type="button"
        onClick={openEdition}
      >
        <div className="d-flex align-items-center ">
          <GearIcon fill="#757575" />
          <span className="ml-2 font-montserrat font-weight-600"> {t('PERSONALIZE_COLUMNS')}</span>
        </div>
      </button>
      <ModalCustom
        show={showPersonalize}
        setShow={setShowPersonalize}
        showCross={false}
        onOkText={t('PERSONALIZE_COLUMNS_APPLY')}
        onCancelText={t('PERSONALIZE_COLUMNS_CANCEL')}
        onOk={applyEdition}
        onCancel={cancelEdition}
        datatestid="personalize-search-table-modal"
        datatestidbuttonok="personalize-search-table-apply-button"
        datatestidbuttoncancel="personalize-search-table-cancel-button"
        classModal="personalization-modal-w"
        input={
          <div>
            <h1 className="font-montserrat font-weight-600 personalize-title">
              {t('PERSONALIZE_COLUMNS')}
            </h1>
            <div className="personalization-modal-h">
              {tablePersonalization
                .sort((a, b) => a.columnOrder - b.columnOrder)
                .map((item, index) => (
                  <PersonalizeColumnItem
                    key={`${item.key}-${rerenderKey}`}
                    columnItem={item}
                    index={index}
                    handleColumnCheck={handleColumnCheck}
                    handleOrder={handleOrder}
                    handlePin={handlePin}
                    allowPin={tablePersonalization.filter((item) => item.pinned).length < 2}
                    disableOrderDown={disableOrder('DOWN', index)}
                    disableOrderUp={disableOrder('UP', index)}
                    disableCheckbox={checkboxDisabled(index)}
                    orderKey="columnOrder"
                  />
                ))}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PersonalizeCanidatesTable;
