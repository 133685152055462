import { useEffect, useRef, useState } from 'react';
import arrowIcon from '../../assets/header/arrow-small.svg';
import worldIcon from '../../assets/header/world-icon.svg';
import useClickOutside from '../../hooks/useClickOutside';
import useTranslations from '../../hooks/useTranslations';
import CountryFlag from '../CountryFlag/CountryFlag';
import './DropdownLanguage.scss';

export const languagesList = [
  { label: 'Español', value: 'es', iso: 'ESP' },
  { label: 'English', value: 'en', iso: 'USA' },
  { label: 'Português', value: 'pt', iso: 'BRA' },
];

const DropdownLanguage = ({ className, position = 'right' }) => {
  const [open, setOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState(false);
  const ref = useRef(null);
  useClickOutside(ref, () => setOpen(false));
  const { changeLanguageKey, key } = useTranslations();

  const handleClick = (value) => {
    changeLanguageKey(value);
    setOpen(false);
    setPlaceholder(value?.toUpperCase() ?? '');
  };

  useEffect(() => {
    if (key) {
      const selectedOption = languagesList.find((option) => option.value && option.value === key);
      if (selectedOption) {
        changeLanguageKey(selectedOption?.value ?? null);
        setPlaceholder(selectedOption?.value?.toUpperCase() ?? '');
      }
    }
  }, [key]);

  return (
    <div
      className={`d-flex align-self-end align-self-sm-center dropdown-lang-max-height ${
        open && 'dropdown-lang-container-index'
      }`}
    >
      <div
        ref={ref}
        className={`rounded font-roboto font-weight-bold non-selectable dropdown-lang-select-container position-relative w-max-content ${className}`}
      >
        <div
          onClick={() => setOpen((state) => !state)}
          aria-hidden
          className="dropdown-lang-select-button cursor-pointer p-1 p-sm-2 w-100 d-flex align-items-center justify-content-between"
        >
          <img src={worldIcon} alt="world" className="d-none d-lg-inline" />
          <span className="dropdown-lang-select-placeholder overflow-hidden ml-sm-2 mr-sm-1">
            {placeholder ?? ''}
          </span>
          <img
            src={arrowIcon}
            alt="arrow"
            className={`dropdown-lang-select-arrow mx-1 ${
              open && 'dropdown-lang-select-arrow-open'
            }`}
          />
        </div>
        <ul
          className={`dropdown-lang-select-options-container bg-white overflow-auto custom-scrollbar m-0 position-absolute ${
            open && 'dropdown-lang-select-options-container-open py-2'
          } ${position === 'right' ? 'left-0' : 'right-0'}`}
        >
          {languagesList.map((option, i) => (
            <li
              className={`p-2 cursor-pointer dropdown-lang-select-option ${
                key === option.value && 'dropdown-lang-selected-option'
              }`}
              key={i}
              onClick={() => handleClick(option.value)}
              onKeyPress={() => handleClick(option.value)}
              role="option"
              aria-selected
            >
              <CountryFlag countryIso={option.iso} size="sm" className="mr-2" />
              {option.label ?? option.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownLanguage;
