export const API =
  process.env.RAZZLE_RUNTIME_ENV === 'production'
    ? 'https://alkymetrics.com.ar/'
    : 'https://test.alkymetrics.com.ar/';
export const HOME = '/';
export const WORKSPACE = '/workspace';
export const CREATED_WORKSPACE = '/createdworkspace';
export const CONFIGURATION = '/configuration';
export const PLANS_CONSUMPTIONS = '/plans';
export const PLAN_PAYMENT_VALIDATE = '/plans/validate';
export const PAYMENT_STATUS = '/payment/status';
export const PAYMENT_SUCCESS = '/payment/success';
export const PAYMENT_PENDING = '/payment/pending';
export const PAYMENT_FAILURE = '/payment/failure';
export const CATEGORIES_TEST = '/categories';
export const ROLES_TESTGROUP = '/rolesTestGroup';
export const CONFIGURATION_MEMBERS = '/configuration/members';
export const SIGNIN = '/login';
export const REGISTER = '/register';
export const FORGOTPASSWORD = '/forgot-password';
export const PROFILES = '/profiles';
export const PROFILES_DETAIL = '/profiles/:id';
export const INVITED_PROFILES_DETAIL = '/search/:recruitSessionId/profiles/:profileId';
export const SCREENING = '/screening';
export const ERROR = '/error';
export const PROFILE = '/profile';
export const NOTIFICATIONS = '/notifications';
export const SAVED_SEARCHES = '/searches';
export const SAVED_PROFILES = '/favorites';
export const TESTS = '/tests';
export const RESULTS = '/results/:id';
export const CREATE_TEST_GROUP = '/createtestgroup';
export const EDIT_TEST_GROUP = '/abm/edit-testgroup/:id';
export const CREATE_ABM_TESTGROUP_SCREENING = '/abm/testgroups-screenings';
export const SCREENING_SEARCHES = '/screening/searches';
export const CREATE_EVALUATION = '/createevaluation';
export const NEW_SEARCH_RESUME = '/createsearch/resume';
export const NEW_SEARCH_SEND = '/createsearch/send';
export const SEARCH_DASHBOARD = '/search/:id';
export const SCREENING_SUCCESS = '/screening-success';
export const CREATE_TEMPLATE = '/create-template';
export const SCREENINGS_PROFILE = '/screening/profiles';
export const INVITED_PROFILES_PROCTORING_DETAIL =
  '/search/:recruitSessionId/profiles/:profileId/proctoringDetail';
export const NEW_SEARCH_SUCCESS = '/createsearch/success';
export const RESET_TEST_GROUP = '/reset-testgroup';
export const CREATE_JOB_POST = '/jobs/create';
export const DETAIL_JOB_POST = '/jobs/:id';
export const EDIT_JOB_POST = '/jobs/:id/edit';
export const VIEW_ALL_JOB_POST = '/jobs';
export const JOB_POST = '/jobs';
export const USERS_SUMMARY = '/users/summary';

export const CERTIFICATES = '/certificates/:userId';
export const RoutesKeys = {
  HOME: 'HOME',
  WORKSPACE: 'WORKSPACE',
  SIGNIN: 'SIGNIN',
  PROFILES: 'PROFILES',
  PROFILES_DETAIL: 'PROFILES_DETAIL',
  SCREENING: 'SCREENING',
  ERROR: 'ERROR',
  PROFILE: 'PROFILE',
  SAVED_SEARCHES: 'SAVED_SEARCHES',
  SAVED_PROFILES: 'SAVED_PROFILES',
  SCREENING_INVITATIONS: 'SCREENING_INVITATIONS',
  CREATE_TEST_GROUP: 'CREATE_TEST_GROUP',
  CREATE_ABM_TESTGROUP_SCREENING: 'CREATE_ABM_TESTGROUP_SCREENING',
  CREATE_CATEGORIES: 'CREATE_CATEGORIES',
  CREATE_ROLES: 'CREATE_ROLES',
  RESET_TEST_GROUP: 'RESET_TEST_GROUP',
  CONFIGURATION: 'CONFIGURATION',
  TEST_CONFIGURATION: 'TEST_CONFIGURATION',
  JOB_POST: 'JOB_POST',
  CREATE_JOB_POST: 'CREATE_JOB_POST',
  EDIT_JOB_POST: 'EDIT_JOB_POST',
  VIEW_ALL_JOB_POST: 'VIEW_ALL_JOB_POST',
  DETAIL_JOB_POST: 'VIEW_JOB_POST',
  CERTIFICATES: 'CERTIFICATES',
};
