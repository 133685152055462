import React, { useState } from 'react';
import { message, Button, Select, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../i18n/i18n';

const EmailTagsInput = ({ emails = [], setEmails = () => {} }) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (value) => {
    const validEmails = value.filter((email) => emailRegex.test(email));
    const invalidEmails = value.filter((email) => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      message.error(t('EMAIL_INVALID'));
    }

    setEmails(validEmails);
  };

  const handleClearAll = () => {
    setEmails([]);
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    return (
      <Tag color="blue" closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder={t('EMAIL_PLACEHOLDER')}
        value={emails}
        onChange={handleEmailChange}
        tagRender={tagRender}
      />
      <Button
        onClick={handleClearAll}
        type="text"
        icon={<FontAwesomeIcon icon={faTrash} />}
        style={{ marginLeft: '8px', color: 'red' }}
        title={t('EMAIL_DELETE_ALL')}
      />
    </div>
  );
};

export default EmailTagsInput;
