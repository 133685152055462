import React, { useEffect, useRef, useState } from 'react';
import { HiSquares2X2 } from 'react-icons/hi2';
import { FaEye, FaStar } from 'react-icons/fa';
import { AiOutlineContacts, AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoFunnel } from 'react-icons/io5';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosClose } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { BsThreeDots } from 'react-icons/bs';
import { Dropdown } from 'antd';
import useCreateScreening from '../../hooks/useCreateScreening';
import { t } from '../../i18n/i18n';
import { inHtmlTag } from '../../utils/inHtmlTag';
import ModalSubmission from '../ModalSubmission/ModalSubmission';
import AlkemyTests from './AlkemyTests/AlkemyTests';
import FavoriteTests from './FavoriteTests/FavoriteTests';
import RoleTests from './RoleTests/RoleTests';
import SelectedTests from './SelectedTests/SelectedTests';
import { TrackEvent } from '../../utils/filters/segmentUtils';
import SearchIcon from '../../assets/screening/SearchIcon';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import TreeMultiSelect from '../TreeMultiSelect/TreeMultiSelect';
import { isNullOrUndefined } from '../../utils/typesUtils';
import { getTestGroup } from '../../API/services/screeningService';
import { setSubmissionSelected } from '../../redux/actions/screeningActions';
import useClickOutside from '../../hooks/useClickOutside';
import useCreateEvaluation from '../../hooks/useCreateEvaluation';
import loadingRecommendations from '../../assets/loadingRecommendations';
import LoadingRecommendations from '../../assets/createEvaluation/loading_recomendations';

const CreateEvaluationSecondStep = ({
  fetchTests,
  loadingTests,
  isTestsPage = false,
  evaluation,
}) => {
  const [buttonSelected, setButtonSelected] = useState(
    isTestsPage ? 'TEST_ALKEMY' : 'SECOND_STEP_CREATE_ALL_TESTS'
  );
  const {
    newScreening,
    testGroupLevelsFilterOptions,
    testGroupRolesFilterOptions,
    fetchLevels,
    fetchCategories,
    fetchRolesWithTestGroups,
    deselectTg,
    newScreeningFilters,
    testGroupCategoriesFilterOptions,
    handleInput,
    handleSubmit,
    handleSelectFilterOption,
    handleSelectFilterOptionMultiple,
    allTests,
    handleTests,
    amountOfFilters,
    handleClearFilters,
  } = useCreateScreening();
  const {
    preSelectionLoading,
    preSelectedTestgroups,
    testgroupsToShowUnselected,
    setTestgroupsData,
  } = useCreateEvaluation();

  const [show, setShow] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const filtersContainerRef = useRef(null);
  const [loadingRecommendations, setLoadingRecommendations] = useState(isTestsPage ? false : true);
  useClickOutside(filtersContainerRef, () => setOpenFilters(false));
  useEffect(async () => {
    if (!testGroupLevelsFilterOptions?.length) {
      fetchLevels();
    }
    fetchCategories(false);
    if (!testGroupRolesFilterOptions?.length) {
      fetchRolesWithTestGroups();
    }
    const getRecommendedTestgroups = async () => {
      await setTestgroupsData();
    };
    if (
      (!testgroupsToShowUnselected.length || !preSelectedTestgroups.length) &&
      newScreening?.testGroups?.filter((item) => item?.isSelected)?.length === 0
    ) {
      await Promise.all([
        getRecommendedTestgroups(),
        new Promise((resolve) => setTimeout(resolve, 4000)),
      ]);
      setLoadingRecommendations(false);
    } else {
      await new Promise((resolve) => setTimeout(resolve, 4000));
      setLoadingRecommendations(false);
    }
    if (isTestsPage) {
      await handleClearFilters();
    }
  }, []);

  const options = isTestsPage
    ? [
        { text: 'TEST_ALKEMY', icon: <HiSquares2X2 /> },
        { text: 'TEST_BY_ROLE', icon: <AiOutlineContacts /> },
        { text: 'MY_FAVORITES', icon: <FaStar /> },
      ]
    : [
        { text: 'SECOND_STEP_CREATE_ALL_TESTS', icon: <HiSquares2X2 /> },
        { text: 'MY_FAVORITES', icon: <FaStar /> },
        {
          text: 'CREATE_EVALUATION_NEED_ANOTHER_TEST',
          icon: <AiOutlineQuestionCircle size="1.5em" />,
          track: true,
          href: process.env.RAZZLE_RUNTIME_TEST_SUGGESTIONS_LINK,
          rel: 'noopener noreferrer',
          target: '_blank',
          dataTestId: 'create-evaluation-ss-link-a',
        },
      ];

  useEffect(() => {
    if (buttonSelected === 'SECOND_STEP_CREATE_ALL_TESTS' || buttonSelected === 'TEST_ALKEMY') {
      fetchTests({ roleIds: {} });
    }
  }, [buttonSelected]);

  const calculateFiltersSelectedLength = () => {
    let count = 0;
    if (newScreeningFilters?.category?.length) {
      count += newScreeningFilters.category.length;
    }
    if (newScreeningFilters?.levelId?.length) {
      count += newScreeningFilters.levelId.length;
    }
    return count;
  };

  const dispatch = useDispatch();
  const getTestGroupId = async (testGroupId) => {
    setTestgroupDetailLoading(true);
    await getTestGroup(testGroupId)
      .then((res) => {
        dispatch(
          setSubmissionSelected({ testGroup: res, submissions: res?.tests?.map((r) => r.test) })
        );
        setShow(true);
      })
      .finally(() => {
        setTestgroupDetailLoading(false);
      });
  };
  const [testgroupDetailLoading, setTestgroupDetailLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleMenuClick = (e) => {
    // if need to close with click on some item whe can add here
  };
  const handleOpenChange = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoadingFilter(true);
    await handleSubmit(e, true);
    setLoadingFilter(false);
  };
  return (
    <div
      data-testid="create-evaluation-second-step"
      className="d-flex flex-column second-step-container pt-1 h-100 w-100"
    >
      {loadingRecommendations ? (
        <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
          <LoadingRecommendations />
          <span className="pt-3 font-weight-600 text-xl">
            {t('CREATE_EVALUATION_LOADING_RECOMMENDATIONS')}
          </span>
        </div>
      ) : (
        <>
          <ModalSubmission show={show} setShow={setShow} isInvitedProfile isTestgroupListDetail />
          <div className="d-flex mb-1">
            <span
              className=" font-weight-600 text-base text-light-black justify-content-start"
              data-testid="create-evaluation-ss-test-title-span"
            >
              {t('CREATE_EVALUATION_SECOND_STEP_TESTS_TITLE')}
            </span>
          </div>
          {!isTestsPage && Boolean(allTests?.length) && (
            <div className="mb-1 w-100 selected-tests-container d-flex flex-column gap-1">
              <span className="text-xs justify-self-end">{t('SELECTED_TESTS')}</span>
              <div className="second-step-selected-tests-container w-100">
                {!isTestsPage &&
                  allTests?.map((testgroup) => (
                    <div className="second-step-selected-tests-item">
                      <OverlayTrigger
                        key={testgroup.id}
                        placement="top"
                        overlay={<Tooltip id={`tooltip-${testgroup.id}`}>{testgroup.name}</Tooltip>}
                      >
                        <span>{testgroup.name}</span>
                      </OverlayTrigger>
                      <div className="d-flex pl-2 align-items-center item-icons">
                        <div
                          onClick={() => {
                            if (!testgroupDetailLoading) getTestGroupId(testgroup?.id);
                          }}
                        >
                          {testgroupDetailLoading ? <BsThreeDots /> : <FaEye size="1.5rem" />}
                        </div>
                        <div onClick={() => handleTests(testgroup, false)}>
                          <IoIosClose size="2rem" />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {isTestsPage && (
            <span
              className="text-light-black font-weight-400 text-base mb-2 "
              dangerouslySetInnerHTML={{
                __html: t('CREATE_EVALUATION_SECOND_STEP_TESTS_SUBTITLE')
                  .replace(
                    '{firstBold}',
                    inHtmlTag({
                      tag: 'b',
                      className: '',
                      content: t('CREATE_EVALUATION_SECOND_STEP_SUBTITLE_FIRST_BOLD'),
                    })
                  )
                  .replace(
                    '{secondBold}',
                    inHtmlTag({
                      tag: 'b',
                      className: '',
                      content: t('CREATE_EVALUATION_SECOND_STEP_SUBTITLE_SECOND_BOLD'),
                    })
                  ),
              }}
              data-testid="create-evaluation-ss-test-subtitle-span"
            />
          )}

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center" style={{ flex: '1', height: 'auto' }}>
              {options?.map(({ text, icon, track, href, rel, target, dataTestId }) => (
                <OverlayTrigger
                  key={text}
                  placement="top"
                  overlay={<Tooltip id={`tooltip-${text}`}>{t(text)}</Tooltip>}
                  className="d-none d-sm-inline"
                >
                  <button
                    key={text}
                    type="button"
                    data-testid={`create-evaluation-second-step-button ${dataTestId}`}
                    className={`bg-transparent p-2 d-flex mr-2 justify-content-center align-items-center button-filter ${
                      text === buttonSelected
                        ? 'test-button-filters-selected'
                        : 'test-button-filters'
                    }`}
                    onClick={() => {
                      if (text === 'TEST_ALKEMY' || text === 'SECOND_STEP_CREATE_ALL_TESTS') {
                        TrackEvent('create-evaluation-search-by-test');
                      }

                      if (!isNullOrUndefined(track)) {
                        TrackEvent('suggest-test-link');
                      } else {
                        setButtonSelected(text);
                      }
                      if (href) {
                        window.open(href, target || '_blank', rel || 'noopener noreferrer');
                      }
                    }}
                  >
                    {icon}
                    <span
                      className={`d-none d-sm-inline test-text-filters ${
                        text === buttonSelected ? 'test-text-filters-selected' : 'test-text-filters'
                      }`}
                      data-testid="create-evaluation-ss-filters-selected-span"
                    >
                      {t(text)}{' '}
                      {text === 'SELECTED_TESTS' && `(${newScreening?.testGroups?.length})`}
                    </span>
                  </button>
                </OverlayTrigger>
              ))}
            </div>
            <div
              className="d-flex align-items-center ml-auto mb-1"
              style={{ flex: '1', height: 'auto' }}
            >
              <div
                className="d-flex align-items-center justify-content-end pr-4"
                style={{ flex: '1' }}
              >
                <form
                  onBlur={async (e) => {
                    try {
                      setLoadingFilter(true);
                      await handleSubmit(e, true);
                    } finally {
                      setLoadingFilter(false);
                    }
                  }}
                  onSubmit={async (e) => {
                    try {
                      setLoadingFilter(true);
                      await handleSubmit(e, true);
                    } finally {
                      setLoadingFilter(false);
                    }
                  }}
                  className="my-1 my-sm-0 rounded transition-2-ms p-2 d-flex align-items-center border-bottom border-2 font-weight-500 text-sm"
                  style={{ flex: '0.7' }}
                >
                  <input
                    onChange={handleInput}
                    value={newScreeningFilters?.name}
                    className="w-100 bg-transparent border-0 outline-none ml-2"
                    style={{ flex: '1' }}
                    placeholder={t('CREATE_SCREENING_SEARCH_PLACEHOLDER')}
                    type="text"
                    data-testid="alkemy-tests-search-input"
                  />
                  <SearchIcon
                    testid="alkemy-tests-search-icon"
                    color={newScreeningFilters?.name?.length ? '#1aacf0' : '#424242'}
                  />
                </form>
              </div>
              <div className="filters-dropdown-container" ref={filtersContainerRef}>
                <Dropdown
                  className="w-100"
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <div>
                            <TreeMultiSelect
                              options={testGroupCategoriesFilterOptions.filter(
                                (category) =>
                                  category?.id !== process.env.RAZZLE_RUNTIME_CATEGORY_ID
                              )}
                              value={newScreeningFilters.category}
                              onChange={async (value) => {
                                if (!loadingFilter) {
                                  setLoadingFilter(true);
                                  try {
                                    await handleSelectFilterOptionMultiple(
                                      value,
                                      'category',
                                      false
                                    );
                                  } finally {
                                    setLoadingFilter(false);
                                  }
                                }
                              }}
                              buttonClassNames={`${
                                newScreeningFilters?.category?.length && 'border-maya-blue'
                              }`}
                              className="select-color drops-filter flex-row mr-2"
                              label={t('MULTIPLE_SELECT_CATEGORIES_PLACEHOLDER')}
                              labelPosition={{ top: 8 }}
                              openDrowdown={openDropdown}
                            />
                          </div>
                        ),
                      },
                      {
                        key: '2',
                        label: (
                          <div>
                            <MultipleSelect
                              openDropdown={openDropdown}
                              title={t('MULTIPLE_SELECT_LEVELS_PLACEHOLDER')}
                              classNames="select-color drops-filter flex-row w-100 justify-content-between"
                              buttonClassNames={`${
                                newScreeningFilters?.levelId?.length && 'border-maya-blue'
                              }`}
                              onSelect={async (e) => {
                                if (!loadingFilter) {
                                  setLoadingFilter(true);
                                  try {
                                    await handleSelectFilterOption(e.id, 'levelId', false);
                                  } finally {
                                    setLoadingFilter(false);
                                  }
                                }
                              }}
                              selectedList={newScreeningFilters.levelId}
                              options={testGroupLevelsFilterOptions}
                            />
                          </div>
                        ),
                      },
                      {
                        key: '3',
                        label: (
                          <div className="d-flex justify-content-center">
                            {amountOfFilters > 0 && (
                              <button
                                onClick={async () => {
                                  setLoadingFilter(true);
                                  try {
                                    await handleClearFilters();
                                  } finally {
                                    setLoadingFilter(false);
                                  }
                                }}
                                className="create-evaluation-secondary-button ml-2 font-montserrat font-weight-700 bg-transparent py-2 px-4 text-xs"
                                type="button"
                                data-testid="alkemy-tests-clear-filters-btn"
                              >
                                {t('PROFILES_CLEAR_FILTERS')}
                              </button>
                            )}
                          </div>
                        ),
                      },
                      {
                        key: '4',
                        label: (
                          <div className="d-flex w-100 align-items-center justify-content-end pr-4">
                            <form
                              onBlur={async () => await handleOnSubmit}
                              onSubmit={async () => await handleOnSubmit}
                              className="my-1 my-sm-0 rounded transition-2-ms p-2 d-flex align-items-center border-bottom border-2 font-weight-500 text-sm w-100"
                            >
                              <input
                                onChange={handleInput}
                                value={newScreeningFilters?.name}
                                className="w-100 bg-transparent border-0 outline-none ml-2"
                                style={{ flex: '1' }}
                                placeholder={t('CREATE_SCREENING_SEARCH_PLACEHOLDER')}
                                type="text"
                                data-testid="alkemy-tests-search-input"
                              />
                              <SearchIcon
                                testid="alkemy-tests-search-icon"
                                color={newScreeningFilters?.name?.length ? '#1aacf0' : '#424242'}
                              />
                            </form>
                          </div>
                        ),
                      },
                    ],
                    onClick: handleMenuClick,
                  }}
                  onOpenChange={handleOpenChange}
                  open={openDropdown}
                >
                  <div className="filters-dropdown-container-toggle">
                    <span>{t('CREATE_SCREENING_FILTERS')}</span>
                    <IoFunnel />
                    <span>{` (${calculateFiltersSelectedLength()})`}</span>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
          {buttonSelected === 'TEST_BY_ROLE' && (
            <RoleTests
              options={testGroupRolesFilterOptions}
              setShow={setShow}
              loadingTests={loadingTests}
              fetchTests={fetchTests}
              isCheck={!isTestsPage}
            />
          )}
          {(buttonSelected === 'TEST_ALKEMY' ||
            buttonSelected === 'SECOND_STEP_CREATE_ALL_TESTS') && (
            <AlkemyTests
              loadingTests={loadingTests}
              setShow={setShow}
              evaluation={evaluation}
              fetchTests={fetchTests}
              isCheck={!isTestsPage}
              preSelectedTestgroups={preSelectedTestgroups}
              testgroupsToShowUnselected={testgroupsToShowUnselected}
              preSelectedTestgroupsIds={preSelectedTestgroups?.map((item) => item.id)}
              preSelectionLoading={preSelectionLoading}
              loadingFilter={loadingFilter}
              setLoadingFilter={setLoadingFilter}
            />
          )}
          {buttonSelected === 'MY_FAVORITES' && (
            <FavoriteTests
              fetchTests={fetchTests}
              loadingTests={loadingTests}
              setShow={setShow}
              isCheck={!isTestsPage}
            />
          )}
          {buttonSelected === 'SELECTED_TESTS' && !isTestsPage && (
            <SelectedTests setShow={setShow} setButtonSelected={setButtonSelected} />
          )}
        </>
      )}
    </div>
  );
};

export default CreateEvaluationSecondStep;
